import {createContext, useContext, useEffect, useState} from "react";
import {WindowHeight, WindowWidth} from "../utilis/windowDimensions";

const WindowDimensionsContext = createContext(undefined as any);

type ProviderWindowDimensionsT = {
    windowWidth: number,
    setWindowWidth: (width: number) => void
    windowHeight: number,
    setWindowHeight: (height: number) => void
    wWCheck: (number: number) => boolean
    wHCheck: (number: number) => boolean
}

export const useWindowDimensions = () : ProviderWindowDimensionsT => {
    return useContext(WindowDimensionsContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const WindowDimensionsProvider = ({children}: IProps) => {
    const [windowWidth, setWindowWidth] = useState(WindowWidth() as number);
    const [windowHeight, setWindowHeight] = useState(WindowHeight() as number);

    // useEffect(() => {
    //     console.log(windowWidth)
    // }, [windowWidth])

    const wWCheck = (number: number) => {
        if (windowWidth > number)
            return true;
        else
            return false;
    }

    const wHCheck = (number: number) => {
        if (windowHeight > number)
            return true;
        else
            return false;
    }

    const value = {
        windowWidth,
        setWindowWidth,
        windowHeight,
        setWindowHeight,
        wWCheck,
        wHCheck
    }

    return (
        <WindowDimensionsContext.Provider value={value}>
            {children}
        </WindowDimensionsContext.Provider>
    );
}

export default WindowDimensionsProvider;
