import React, {useEffect, useRef, useState} from "react";
import "./../assets/css/constants.css";
import {useText} from "../assets/contexts/textContext";
import texts, {ITextHome} from '../assets/texts/home';
import textsProjects, {ITextProjects} from '../assets/texts/projects';
import textsHeader, {ITextHeader} from '../assets/texts/header';
import {Box, Flex} from 'rebass';
import MyText from "../components/basics/MyText";
import MyLink from "../components/basics/MyLink";
import Scroll from "react-scroll";
import ArrowDown from "../assets/icons/arrowDown.svg";
import ArrowUp from "../assets/icons/arrowUp.svg";
import ArrowRight from "../assets/icons/arrowRight.svg";
import ArrowRight2 from "../assets/icons/arrowRight2.svg";
import BlackArrow from "../assets/icons/blackArrow.png";
import MyImage from "../components/basics/MyImage";
import {useWindowDimensions} from "../assets/contexts/windowDimensionsContext";
import ThomPicture from "../assets/pictures/thom.png";
import {useNavigate, useSearchParams} from "react-router-dom";
import ArrowNavigation from "../components/navigation/arrowNavigation";
import {useCard} from "../assets/contexts/CardContext";
import {useHome} from "../assets/contexts/HomeContext";
import MyInputText from "../components/basics/MyInputText";
import MyButton from "../components/basics/MyButton";
import {useAuth} from "../assets/contexts/AuthContext";

interface ICard {
    title: string;
    description: string;
    paragraph1: string;
    paragraph2: string;
}

interface ICardMid {
    title1: string;
    description1: string;
    paragraph11: string;
    paragraph21: string;
    title2: string;
    description2: string;
    paragraph12: string;
    paragraph22: string;
}

const Card = (props: ICard) => {
    const [open, setOpen] = React.useState(false);
    const [arrowDownHovered, setArrowDownHovered] = React.useState(false);
    const cardContext = useCard();

    return (
        <Flex id={"card" + props.title} flexDirection={"column"} minWidth={"290px"} margin={"3px"} width={"20%"}
              height={"100%"} minHeight={"240px"} style={{
            transition: "margin-bottom 1s ease -0.1s",
            borderRadius: "var(--BorderRadius)",
            border: "2px solid var(--White)",
            position: "relative"
        }} onClick={() => {
            if (!open) cardContext.setOpened([...cardContext.opened, props.title]);
            if (open) cardContext.setOpened(cardContext.opened.filter((e) => e !== props.title));
            setOpen(!open)
        }}>
            <MyText value={props.title} style={{
                color: "var(--White)",
                margin: "20px",
                fontSize: "var(--MediumHL)",
                lineHeight: "93.5%",
                letterSpacing: "-4%"
            }}/>
            <MyText value={props.description} style={{
                color: "var(--White)",
                margin: "20px",
                fontSize: "var(--MediumHL)",
                lineHeight: "105.5%",
                letterSpacing: "-4%"
            }}/>
            {!open && <Flex width={"100%"} justifyContent={"end"} style={{position: "relative", bottom: "0px"}}>
                <MyImage src={ArrowDown} style={{
                    color: "var(--White)",
                    margin: "20px",
                    cursor: "pointer",
                    transform: arrowDownHovered ? "translateY(10px)" : "",
                    transition: "transform 0.5s ease"
                }} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
            </Flex>}
            <Flex flexDirection={"column"} style={{
                height: open ? "430px" : "0",
                transition: !open ? "height 0.4s ease -0.1s" : "height 1s ease -0.1s",
                overflow: "hidden"
            }}>
                <MyText value={props.paragraph1} className={open ? "fadeDisplayAnim" : "noOpacity"} style={{
                    color: "var(--White)",
                    margin: "20px",
                    marginTop: "20px",
                    fontSize: "var(--Small)",
                    lineHeight: "100.5%",
                    letterSpacing: "-4%"
                }}/>
                <MyText value={props.paragraph2} className={open ? "fadeDisplayAnim2" : "noOpacity"} style={{
                    color: "var(--White)",
                    margin: "20px",
                    fontSize: "var(--Small)",
                    lineHeight: "100.5%",
                    letterSpacing: "-4%"
                }}/>
            </Flex>
            {open && <Flex width={"100%"} justifyContent={"end"}>
                <MyImage src={ArrowUp} style={{
                    color: "var(--White)",
                    margin: "20px",
                    cursor: "pointer",
                    transform: arrowDownHovered ? "translateY(-10px)" : "",
                    transition: "transform 0.5s ease"
                }} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
            </Flex>}
        </Flex>
    )
}

const CardMid = (props: ICardMid) => {
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [arrowDownHovered, setArrowDownHovered] = React.useState(false);
    const cardContext = useCard();

    return (
        <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={"100%"}
              minHeight={"240px"}>
            <Flex id={"card" + props.title1} flexDirection={"column"} minWidth={"290px"} margin={"20px"} width={"20%"}
                  style={{
                      transition: "margin-bottom 1s ease -0.1s",
                      borderRadius: "var(--BorderRadius)",
                      border: "2px solid var(--White)",
                      position: "relative"
                  }} onClick={() => {
                if (!open1) cardContext.setOpened([...cardContext.opened, props.title1]);
                if (open1) cardContext.setOpened(cardContext.opened.filter((e) => e !== props.title1));
                setOpen1(!open1)
            }}>
                <MyText value={props.title1} style={{
                    color: "var(--White)",
                    margin: "20px",
                    fontSize: "var(--MediumHL)",
                    lineHeight: "93.5%",
                    letterSpacing: "-4%"
                }}/>
                <MyText value={props.description1} style={{
                    color: "var(--White)",
                    margin: "20px",
                    fontSize: "var(--MediumHL)",
                    lineHeight: "105.5%",
                    letterSpacing: "-4%"
                }}/>
                {!open1 && <Flex width={"100%"} justifyContent={"end"} style={{position: "relative", bottom: "0px"}}>
                    <MyImage src={ArrowDown} style={{
                        color: "var(--White)",
                        margin: "20px",
                        cursor: "pointer",
                        transform: arrowDownHovered ? "translateY(10px)" : "",
                        transition: "transform 0.5s ease"
                    }} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
                </Flex>}
                <Flex flexDirection={"column"} style={{
                    height: open1 ? "400px" : "0",
                    transition: !open1 ? "height 0.4s ease -0.1s" : "height 1s ease -0.1s",
                    overflow: "hidden"
                }}>
                    <MyText value={props.paragraph11} className={open1 ? "fadeDisplayAnim" : "noOpacity"} style={{
                        color: "var(--White)",
                        margin: "20px",
                        marginTop: "20px",
                        fontSize: "var(--Small)",
                        lineHeight: "100.5%",
                        letterSpacing: "-4%"
                    }}/>
                    <MyText value={props.paragraph21} className={open1 ? "fadeDisplayAnim2" : "noOpacity"} style={{
                        color: "var(--White)",
                        margin: "20px",
                        fontSize: "var(--Small)",
                        lineHeight: "100.5%",
                        letterSpacing: "-4%"
                    }}/>
                </Flex>
                {open1 && <Flex width={"100%"} justifyContent={"end"}>
                    <MyImage src={ArrowUp} style={{
                        color: "var(--White)",
                        margin: "20px",
                        cursor: "pointer",
                        transform: arrowDownHovered ? "translateY(-10px)" : "",
                        transition: "transform 0.5s ease"
                    }} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
                </Flex>}
            </Flex>
            <Flex id={"card" + props.title2} flexDirection={"column"} minWidth={"290px"} margin={"20px"} width={"20%"}
                  style={{
                      transition: "margin-bottom 1s ease -0.1s",
                      borderRadius: "var(--BorderRadius)",
                      border: "2px solid var(--White)",
                      position: "relative"
                  }} onClick={() => {
                if (!open2) cardContext.setOpened([...cardContext.opened, props.title2]);
                if (open2) cardContext.setOpened(cardContext.opened.filter((e) => e !== props.title2));
                setOpen2(!open2)
            }}>
                <MyText value={props.title2} style={{
                    color: "var(--White)",
                    margin: "20px",
                    fontSize: "var(--MediumHL)",
                    lineHeight: "93.5%",
                    letterSpacing: "-4%"
                }}/>
                <MyText value={props.description2} style={{
                    color: "var(--White)",
                    margin: "20px",
                    fontSize: "var(--MediumHL)",
                    lineHeight: "90.5%",
                    letterSpacing: "-4%"
                }}/>
                {!open2 && <Flex width={"100%"} justifyContent={"end"} style={{position: "relative", bottom: "0px"}}>
                    <MyImage src={ArrowDown} style={{
                        color: "var(--White)",
                        margin: "20px",
                        cursor: "pointer",
                        transform: arrowDownHovered ? "translateY(10px)" : "",
                        transition: "transform 0.5s ease"
                    }} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
                </Flex>}
                <Flex flexDirection={"column"} style={{
                    height: open2 ? "400px" : "0",
                    transition: !open2 ? "height 0.4s ease -0.1s" : "height 1s ease -0.1s",
                    overflow: "hidden"
                }}>
                    <MyText value={props.paragraph12} className={open2 ? "fadeDisplayAnim" : "noOpacity"} style={{
                        color: "var(--White)",
                        margin: "20px",
                        marginTop: "20px",
                        fontSize: "var(--Small)",
                        lineHeight: "100.5%",
                        letterSpacing: "-4%"
                    }}/>
                    <MyText value={props.paragraph22} className={open2 ? "fadeDisplayAnim2" : "noOpacity"} style={{
                        color: "var(--White)",
                        margin: "20px",
                        fontSize: "var(--Small)",
                        lineHeight: "100.5%",
                        letterSpacing: "-4%"
                    }}/>
                </Flex>
                {open2 && <Flex width={"100%"} justifyContent={"end"}>
                    <MyImage src={ArrowUp} style={{
                        color: "var(--White)",
                        margin: "20px",
                        cursor: "pointer",
                        transform: arrowDownHovered ? "translateY(-10px)" : "",
                        transition: "transform 0.5s ease"
                    }} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
                </Flex>}
            </Flex>
        </Flex>
    )
}

const CardSmall = (props: ICard) => {
    const cardContext = useCard();
    const [open, setOpen] = useState(props.title.includes("01") ? cardContext.first : props.title.includes("02") ? cardContext.second : props.title.includes("03") ? cardContext.third : props.title.includes("04") ? cardContext.fourth : false);
    const [arrowDownHovered, setArrowDownHovered] = useState(false);
    const windowDimensions = useWindowDimensions();

    useEffect(() => {
        if (props.title.includes("01"))
            setOpen(cardContext.first)
    }, [cardContext.first])

    useEffect(() => {
        if (props.title.includes("02"))
            setOpen(cardContext.second)
    }, [cardContext.second])

    useEffect(() => {
        if (props.title.includes("03"))
            setOpen(cardContext.third)
    }, [cardContext.third])

    useEffect(() => {
        if (props.title.includes("04"))
            setOpen(cardContext.fourth)
    }, [cardContext.fourth])

    return (
        // <div>
        // <Flex id={"card"+props.title} flexDirection={"column"} backgroundColor={"var(--Grey)"} minWidth={"300px"} width={"20%"} height={open ? "100%" : cardContext.selected === "" && props.title === "01." ? windowDimensions.wWCheck(670) ? "250px" : !windowDimensions.wWCheck(617) ? "200px" : "235px" : "85px"} style={{transition: "margin-top 0.5s ease -0.1s", borderRadius: "var(--BorderRadius)", border: "2px solid var(--White)", overflow: "hidden", marginTop: cardContext.selected === "" ? "-25px" : (Number(props.title.match(/\d+/)![0]) + 1) === Number(cardContext.selected.match(/\d+/)![0]) ? "-25px" : "-55px", marginBottom: open ? "30px" : ""}} onClick={() => {if (!open) cardContext.setOpened([...cardContext.opened, props.title]); if (open) cardContext.setOpened(cardContext.opened.filter((e) => e !== props.title)); setOpen(!open); cardContext.setSelected(open ? "" : props.title)}}>
        //     <MyText value={props.title} style={{transition: "opacity 0.1s ease", color: "var(--White)", margin: "20px", fontSize: "var(--PageTitleFontSize)", opacity: cardContext.selected === "" ? props.title !== "01." ? cardContext.selected === "" ? "1" : "0" : "1" : cardContext.selected === props.title ? "1" : "0", lineHeight: "93.5%", letterSpacing: "-4%"}}/>
        //     <MyText value={props.description} className={props.title === "01." ? "" : open ? "fadeDisplayAnim" : "noOpacity"} style={{color: "var(--White)", margin: "20px", marginTop: "25px", fontSize: "var(--PageTitleFontSize)", lineHeight: "105.5%", letterSpacing: "-4%"}}/>
        //     {!open && <Flex width={"100%"} justifyContent={"end"}>
        //         <MyImage src={ArrowDown} style={{color: "var(--White)", margin: "20px", cursor: "pointer", transform: arrowDownHovered ? "translateY(10px)" : "", transition: "transform 0.5s ease"}} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
        //     </Flex>}
        //     <Flex flexDirection={"column"} style={{maxHeight: open ? "600px" : "0", transition: !open ? "max-height 0.4s ease -0.1s" : "max-height 1s ease -0.1s", overflow: "hidden"}}>
        //         <MyText value={props.paragraph1} className={open ? "fadeDisplayAnim2" : "noOpacity"} style={{color: "var(--White)", margin: "20px", marginTop: "20px", fontSize: "var(--MidFontSize)", lineHeight: "100.5%", letterSpacing: "-4%"}}/>
        //         <MyText value={props.paragraph2} className={open ? "fadeDisplayAnim3" : "noOpacity"} style={{color: "var(--White)", margin: "20px", fontSize: "var(--MidFontSize)", lineHeight: "100.5%", letterSpacing: "-4%"}}/>
        //     </Flex>
        //     {open && <Flex width={"100%"} justifyContent={"end"}>
        //         <MyImage src={ArrowUp} style={{color: "var(--White)", margin: "20px", cursor: "pointer", transform: arrowDownHovered ? "translateY(-10px)" : "", transition: "transform 0.5s ease"}} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
        //     </Flex>}
        // </Flex>

        <Flex id={"card" + props.title} flexDirection={"column"} backgroundColor={"var(--Grey)"} marginRight={"30px"}
              marginLeft={"30px"} minWidth={"300px"} width={"100%"}
              height={open ? "100%" : !cardContext.second && !cardContext.third && !cardContext.fourth && props.title === "01." ? windowDimensions.wWCheck(670) ? "250px" : !windowDimensions.wWCheck(617) ? "200px" : "235px" : "85px"}
              style={{
                  transition: "margin-top 0.5s ease -0.1s",
                  borderRadius: "var(--BorderRadius)",
                  border: "2px solid var(--White)",
                  overflow: "hidden",
                  marginTop: cardContext.selected === "" ? "-25px" : (Number(props.title.match(/\d+/)![0]) + 1) === Number(cardContext.selected.match(/\d+/)![0]) ? "-25px" : "-55px",
                  marginBottom: open ? "30px" : ""
              }} onClick={() => {
            props.title === "01." ? cardContext.setFirst(!cardContext.first) : props.title === "02." ? cardContext.setSecond(!cardContext.second) : props.title === "03." ? cardContext.setThird(!cardContext.third) : cardContext.setFourth(!cardContext.fourth)
        }}>
            <MyText value={props.title} style={{
                transition: "opacity 0.1s ease",
                color: "var(--White)",
                margin: "20px",
                fontSize: "var(--PageTitleFontSize)",
                lineHeight: "93.5%",
                letterSpacing: "-4%"
            }}/>
            <MyText value={props.description} style={{
                color: "var(--White)",
                margin: "20px",
                marginTop: "25px",
                fontSize: "var(--PageTitleFontSize)",
                lineHeight: "105.5%",
                letterSpacing: "-4%"
            }}/>
            {!open && <Flex width={"100%"} justifyContent={"end"}>
                {/*<MyImage src={ArrowDown} style={{color: "var(--White)", margin: "20px", cursor: "pointer", transform: arrowDownHovered ? "translateY(10px)" : "", transition: "transform 0.5s ease"}} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)} onclick={() => {if (!open) cardContext.setFirst(true)}}/>*/}
                <MyImage src={ArrowDown} style={{
                    color: "var(--White)",
                    margin: "20px",
                    cursor: "pointer",
                    transform: arrowDownHovered ? "translateY(10px)" : "",
                    transition: "transform 0.5s ease"
                }} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
            </Flex>}
            <Flex flexDirection={"column"} style={{
                maxHeight: open ? "600px" : "0",
                transition: open ? "max-height 1s ease -0.1s" : "max-height 0.4s ease -0.1s",
                overflow: "hidden"
            }}>
                <MyText value={props.paragraph1} style={{
                    color: "var(--White)",
                    margin: "20px",
                    marginTop: "20px",
                    fontSize: "var(--MidFontSize)",
                    lineHeight: "100.5%",
                    letterSpacing: "-4%"
                }}/>
                <MyText value={props.paragraph2} style={{
                    color: "var(--White)",
                    margin: "20px",
                    fontSize: "var(--MidFontSize)",
                    lineHeight: "100.5%",
                    letterSpacing: "-4%"
                }}/>
            </Flex>
            {open && <Flex width={"100%"} justifyContent={"end"}>
                {/*<MyImage src={ArrowUp} style={{color: "var(--White)", margin: "20px", cursor: "pointer", transform: arrowDownHovered ? "translateY(-10px)" : "", transition: "transform 0.5s ease"}} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)} onclick={() => {if (open) cardContext.setFirst(false)}}/>*/}
                <MyImage src={ArrowUp} style={{
                    color: "var(--White)",
                    margin: "20px",
                    cursor: "pointer",
                    transform: arrowDownHovered ? "translateY(-10px)" : "",
                    transition: "transform 0.5s ease"
                }} onMouseOver={() => setArrowDownHovered(true)} onMouseOut={() => setArrowDownHovered(false)}/>
            </Flex>}
        </Flex>
// </div>
    )
}

interface IProjectCard {
    itemId: string;
    color: string;
    background: string;
    title: string;
    description: string;
    number: number;
}

function ProjectCardSmall(props: IProjectCard) {
    const windowDimensions = useWindowDimensions();
    const navigate = useNavigate();
    const cardContext = useCard();
    const scroll = Scroll.animateScroll;
    const language = useText().currentLanguage;

    const ScrollMore = () => {
        document.getElementById("container")!.scrollLeft += (windowDimensions.windowWidth + 20);
    }

    const ScrollLess = () => {
        document.getElementById("container")!.scrollLeft -= (windowDimensions.windowWidth + 20);
    }

    return (
        // <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"space-evenly"} width={windowDimensions.windowWidth - 120 + "px"} height={windowDimensions.windowHeight - 100 + "px"} minHeight={"400px"} backgroundColor={props.background} marginRight={"20px"} style={{position: "relative"}} onDoubleClick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+props.itemId)}}>
        //     <MyImage src={"/images/projects/homePictures/Project"+(props.number+1)+".png"} style={{objectFit: "contain", pointerEvents: "none", height: windowDimensions.windowHeight - 300 + "px", minHeight: "200px"}}/>
        //     <Flex flexDirection={"column"} alignItems={"center"} width={"400px"}>
        //         <MyText value={props.title} style={{color: props.color, background: props.background, borderRadius: "5px", padding: "5px", fontSize: "var(--TitleFontSize)", inlineSize: "300px",overflowWrap: "break-word"}}/>
        //         <MyText value={props.description} style={{color: props.color, background: props.background, borderRadius: "5px", padding: "5px", fontSize: "var(--SmallFontSize)", inlineSize: "300px", marginTop: "20px", marginBottom: "20px", fontFamily: "Inter-Light"}}/>
        //         <MyImage src={ArrowRight2} style={{width: "150px", height: "20px", marginRight: "auto"}} onclick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+props.itemId)}}/>
        //     </Flex>
        // </Flex>
        // <div style={{position: "relative", width: windowDimensions.windowWidth - 70 + "px", height: windowDimensions.windowHeight - 100 + "px", minHeight: "400px", backgroundColor: props.background, marginRight: "20px", marginTop: cardContext.opened.length === 0 ? "400px" : "20px", transition: cardContext.opened.length === 0 ? "margin-top 0.4s ease -0.1s" : "margin-top 1s ease -0.1s"}} onClick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+props.itemId)}}>
        //     <div style={{width: windowDimensions.windowWidth - 70 + "px"}}></div>
        //     <MyImage src={"/images/projects/homePictures/Project"+(props.number+1)+".png"} style={{objectFit: "cover", pointerEvents: "none", height: windowDimensions.windowHeight - 100 + "px", minHeight: "200px", background: "red"}}/>
        //     <Flex flexDirection={"column"} alignItems={"center"} marginRight={"15px"} marginLeft={"15px"} style={{position: "relative", bottom: "200px"}}>
        //         <MyText value={props.title} style={{color: props.color, background: props.background, borderRadius: "5px", padding: "5px", fontSize: "var(--MediumHL)", overflowWrap: "break-word"}}/>
        //         <MyText value={props.description} style={{color: props.color, background: props.background, borderRadius: "5px", padding: "5px", fontSize: "16px", marginTop: "20px", marginBottom: "20px", fontFamily: "Inter-Light"}}/>
        //     </Flex>
        // </div>
        <div id={"projects"} style={{
            position: "relative",
            width: windowDimensions.windowWidth + "px",
            marginTop: cardContext.opened.length === 0 && (!cardContext.first && !cardContext.second && !cardContext.third && !cardContext.fourth) ? "400px" : "20px",
            transition: cardContext.opened.length === 0 && (!cardContext.first && !cardContext.second && !cardContext.third && !cardContext.fourth) ? "margin-top 0.4s ease -0.1s" : "margin-top 1s ease -0.1s",
            marginRight: props.number != (textsProjects.get(language) as ITextProjects).projects.length - 1 ? "20px" : "0",
            background: "var(--White)"
        }}>
            <div style={{width: windowDimensions.windowWidth + "px"}}></div>
            <div style={{display: "flex", flexDirection: "column"}} onClick={() => {
                scroll.scrollToTop({duration: 0});
                navigate("/project-description?id=" + props.itemId)
            }}>
                <MyImage src={"/images/projects/homePictures/Project" + (props.number + 1) + ".png"}
                         style={{objectFit: "cover", pointerEvents: "none", height: "420px"}}/>
                <Flex flexDirection={"column"} marginTop={"20px"} marginBottom={"5px"} marginRight={"15px"}
                      marginLeft={"15px"}>
                    <MyText value={props.title} style={{
                        color: props.color,
                        background: props.background,
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "44px",
                        paddingRight: "44px",
                        fontSize: "var(--MediumHL)",
                        overflowWrap: "break-word"
                    }}/>
                    <MyText value={props.description} style={{
                        color: props.color,
                        background: props.background,
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "44px",
                        paddingRight: "44px",
                        fontSize: "16px",
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontFamily: "Inter-Light"
                    }}/>
                </Flex>
            </div>
            {props.number != (textsProjects.get(language) as ITextProjects).projects.length - 1 &&
                <MyImage src={BlackArrow} style={{
                    position: "absolute",
                    height: "25px",
                    top: "0",
                    bottom: "0",
                    margin: "auto",
                    right: "15px"
                }} onclick={ScrollMore}/>}
            {props.number !== 0 && <MyImage src={BlackArrow} style={{
                position: "absolute",
                height: "25px",
                top: "0",
                bottom: "0",
                margin: "auto",
                left: "15px",
                transform: "rotate(180deg)"
            }} onclick={ScrollLess}/>}
        </div>
    );
}

function ProjectCard(props: IProjectCard) {
    const windowDimensions = useWindowDimensions();
    const navigate = useNavigate();
    const cardContext = useCard()
    const scroll = Scroll.animateScroll;

    return (
        // <Flex width={windowDimensions.windowWidth - 200 + "px"} height={windowDimensions.windowHeight - 100 + "px"} minHeight={"400px"} backgroundColor={props.background} marginRight={"20px"} style={{position: "relative"}} onDoubleClick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+props.itemId)}}>
        //     <MyImage src={"/images/projects/homePictures/Project"+(props.number+1)+".png"} style={{objectFit: "contain", pointerEvents: "none", position: "absolute", right: "0", height: windowDimensions.windowHeight - 100 + "px", minHeight: "400px"}}/>
        //     <Flex flexDirection={"column"} marginTop={"auto"} marginLeft={"60px"} marginRight={"10px"} marginBottom={"80px"} width={"400px"} style={{zIndex: "1"}}>
        //         <MyText value={props.title} style={{color: props.color, background: props.background, borderRadius: "5px", padding: "5px", fontSize: "var(--TitleFontSize)", inlineSize: "300px",overflowWrap: "break-word"}}/>
        //         <MyText value={props.description} style={{color: props.color, background: props.background, borderRadius: "5px", padding: "5px", fontSize: "var(--SmallFontSize)", inlineSize: "300px", marginTop: "20px", marginBottom: "20px", fontFamily: "Inter-Light"}}/>
        //         <MyImage src={ArrowRight2} style={{width: "12%"}} onclick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+props.itemId)}}/>
        //     </Flex>
        // </Flex>
        <div id={"projects"} style={{
            position: "relative",
            width: windowDimensions.windowWidth - 200 + "px",
            height: windowDimensions.windowHeight - 100 + "px",
            minHeight: "400px",
            backgroundColor: props.background,
            marginRight: "20px",
            marginTop: cardContext.opened.length === 0 ? "400px" : "20px",
            transition: cardContext.opened.length === 0 ? "margin-top 0.4s ease -0.1s" : "margin-top 1s ease -0.1s"
        }} onClick={() => {
            scroll.scrollToTop({duration: 0});
            navigate("/project-description?id=" + props.itemId)
        }}>
            <div style={{width: windowDimensions.windowWidth - 200 + "px"}}></div>
            <MyImage src={"/images/projects/homePictures/Project" + (props.number + 1) + ".png"} style={{
                objectFit: "cover",
                pointerEvents: "none",
                position: "absolute",
                width: "100%",
                height: windowDimensions.windowHeight - 150 + "px",
                minHeight: "400px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}/>
            <Flex flexDirection={"column"} marginTop={"auto"} marginLeft={"60px"} marginRight={"10px"}
                  marginBottom={"80px"} width={"400px"}
                  style={{position: "absolute", bottom: "0px", left: "0px", zIndex: "1"}}>
                <MyText value={props.title} style={{
                    color: props.color,
                    background: props.background,
                    borderRadius: "5px",
                    padding: "5px",
                    fontSize: "var(--MediumHL)",
                    inlineSize: "400px",
                    overflowWrap: "break-word"
                }}/>
                <MyText value={props.description} style={{
                    color: props.color,
                    background: props.background,
                    borderRadius: "5px",
                    padding: "5px",
                    fontSize: "16px",
                    inlineSize: "330px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    fontFamily: "Inter-Light"
                }}/>
                <MyImage src={ArrowRight2} style={{width: "12%"}} onclick={() => {
                    scroll.scrollToTop({duration: 0});
                    navigate("/project-description?id=" + props.itemId)
                }}/>
            </Flex>
        </div>
    );
}

const Home = () => {
    const language = useText().currentLanguage;
    const [searchParams] = useSearchParams();
    const scroll = Scroll.animateScroll;
    const scroller = Scroll.scroller
    const navigate = useNavigate();
    const windowDimensions = useWindowDimensions();
    const homeContext = useHome();
    const authContext = useAuth();

    useEffect(() => {
        if (searchParams.get("scrollto")) {
            if (searchParams.get("scrollto") === "about")
                scroller.scrollTo("about", {
                    duration: 1500,
                    smooth: true,
                    offset: windowDimensions.wWCheck(500) ? -75 : -45
                })
            if (searchParams.get("scrollto") === "projects")
                scroller.scrollTo("projects", {
                    duration: 1000,
                    smooth: true,
                    offset: windowDimensions.wWCheck(500) ? -40 : -50
                })
        }
        navigate("/")
    }, [window.location.href])

    const Section1 = () => {
        const windowDimensions = useWindowDimensions();

        return (
            <Box minHeight={windowDimensions.windowHeight - 450 + "px"}
                 marginLeft={windowDimensions.wWCheck(850) ? "var(--PageMargin)" : "var(--PageMarginMobile)"}
                 marginRight={windowDimensions.wWCheck(850) ? "var(--PageMargin)" : "var(--PageMarginMobile)"}
                 marginTop={"240px"} marginBottom={"150px"}>
                <Flex flexWrap={"wrap"} alignItems={"center"}>
                    <MyText className={homeContext.frictionClicked ? "fadeOutDisplayAnim3" : ""}
                            value={(texts.get(language) as ITextHome).mainTitle[0]} style={{
                        color: "var(--White)",
                        fontSize: "var(--GiantFontSize)",
                        lineHeight: "93.5%",
                        letterSpacing: "-4%"
                    }}/>
                    <Flex alignItems={"center"}>
                        <MyText value={(texts.get(language) as ITextHome).mainTitle[1]} style={{
                            color: "var(--White)",
                            fontSize: "var(--FrictionAsteriscFontSize)",
                            fontFamily: "Libre-Baskerville-Italic"
                        }}/>
                        <MyLink value={(texts.get(language) as ITextHome).mainTitle[2]} style={{
                            color: "var(--White)",
                            fontSize: "var(--FrictionFontSize)",
                            textDecoration: "underline",
                            textUnderlineOffset: "8px",
                            textDecorationThickness: "2px",
                            fontFamily: "Libre-Baskerville-Italic"
                        }} onClick={() => {
                            scroll.scrollToTop();
                            homeContext.setFrictionClicked(true);
                            setTimeout(() => navigate("/friction"), 700)
                        }}/>
                    </Flex>
                    <MyText className={homeContext.frictionClicked ? "fadeOutDisplayAnim3" : ""}
                            value={(texts.get(language) as ITextHome).mainTitle[3]} style={{
                        color: "var(--White)",
                        fontSize: "var(--GiantFontSize)",
                        lineHeight: "93.5%",
                        letterSpacing: "-4%"
                    }}/>
                    <MyText className={homeContext.frictionClicked ? "fadeOutDisplayAnim3" : ""}
                            value={(texts.get(language) as ITextHome).mainTitle[4]} style={{
                        color: "var(--White)",
                        fontSize: "var(--GiantFontSize)",
                        lineHeight: "93.5%",
                        letterSpacing: "-4%"
                    }}/>
                    <MyText className={homeContext.frictionClicked ? "fadeOutDisplayAnim3" : ""}
                            value={(texts.get(language) as ITextHome).mainTitle[5]} style={{
                        color: "var(--White)",
                        fontSize: "var(--GiantFontSize)",
                        lineHeight: "93.5%",
                        letterSpacing: "-4%"
                    }}/>
                </Flex>
            </Box>
        )
    }

    const Section2 = () => {
        const split = () => {
            // if (windowDimensions.wWCheck(1430))
            if (windowDimensions.wWCheck(1295))
                return (
                    <Flex className={homeContext.frictionClicked ? "fadeOutDisplayAnim3" : ""} flexWrap={"wrap"}
                          width={"100%"} justifyContent={"space-between"} paddingLeft={"55px"} paddingRight={"55px"}>
                        <Card title={"01."} description={(texts.get(language) as ITextHome).dropdown[0][0]}
                              paragraph1={(texts.get(language) as ITextHome).dropdown[0][1]}
                              paragraph2={(texts.get(language) as ITextHome).dropdown[0][2]}/>
                        <Card title={"02."} description={(texts.get(language) as ITextHome).dropdown[1][0]}
                              paragraph1={(texts.get(language) as ITextHome).dropdown[1][1]}
                              paragraph2={(texts.get(language) as ITextHome).dropdown[1][2]}/>
                        <Card title={"03."} description={(texts.get(language) as ITextHome).dropdown[2][0]}
                              paragraph1={(texts.get(language) as ITextHome).dropdown[2][1]}
                              paragraph2={(texts.get(language) as ITextHome).dropdown[2][2]}/>
                        <Card title={"04."} description={(texts.get(language) as ITextHome).dropdown[3][0]}
                              paragraph1={(texts.get(language) as ITextHome).dropdown[3][1]}
                              paragraph2={(texts.get(language) as ITextHome).dropdown[3][2]}/>
                    </Flex>
                )
            else if (windowDimensions.wWCheck(790))
                return (
                    <Flex className={homeContext.frictionClicked ? "fadeOutDisplayAnim3" : ""} width={"100%"}
                          justifyContent={"space-between"} paddingLeft={"55px"} paddingRight={"55px"}>
                        <CardMid title1={"01."} description1={(texts.get(language) as ITextHome).dropdown[0][0]}
                                 paragraph11={(texts.get(language) as ITextHome).dropdown[0][1]}
                                 paragraph21={(texts.get(language) as ITextHome).dropdown[0][2]} title2={"03."}
                                 description2={(texts.get(language) as ITextHome).dropdown[2][0]}
                                 paragraph12={(texts.get(language) as ITextHome).dropdown[2][1]}
                                 paragraph22={(texts.get(language) as ITextHome).dropdown[2][2]}/>
                        <CardMid title1={"02."} description1={(texts.get(language) as ITextHome).dropdown[1][0]}
                                 paragraph11={(texts.get(language) as ITextHome).dropdown[1][1]}
                                 paragraph21={(texts.get(language) as ITextHome).dropdown[1][2]} title2={"04."}
                                 description2={(texts.get(language) as ITextHome).dropdown[3][0]}
                                 paragraph12={(texts.get(language) as ITextHome).dropdown[3][1]}
                                 paragraph22={(texts.get(language) as ITextHome).dropdown[3][2]}/>
                    </Flex>
                )
            else
                return (
                    <Flex className={homeContext.frictionClicked ? "fadeOutDisplayAnim3" : ""} flexDirection={"column"}
                          alignItems={"center"} width={"100%"} paddingLeft={"55px"} paddingRight={"55px"}>
                        <CardSmall title={"04."} description={(texts.get(language) as ITextHome).dropdown[3][0]}
                                   paragraph1={(texts.get(language) as ITextHome).dropdown[3][1]}
                                   paragraph2={(texts.get(language) as ITextHome).dropdown[3][2]}/>
                        <CardSmall title={"03."} description={(texts.get(language) as ITextHome).dropdown[2][0]}
                                   paragraph1={(texts.get(language) as ITextHome).dropdown[2][1]}
                                   paragraph2={(texts.get(language) as ITextHome).dropdown[2][2]}/>
                        <CardSmall title={"02."} description={(texts.get(language) as ITextHome).dropdown[1][0]}
                                   paragraph1={(texts.get(language) as ITextHome).dropdown[1][1]}
                                   paragraph2={(texts.get(language) as ITextHome).dropdown[1][2]}/>
                        <CardSmall title={"01."} description={(texts.get(language) as ITextHome).dropdown[0][0]}
                                   paragraph1={(texts.get(language) as ITextHome).dropdown[0][1]}
                                   paragraph2={(texts.get(language) as ITextHome).dropdown[0][2]}/>
                    </Flex>
                )
        }

        return (
            <Box>
                {split()}
            </Box>
        )
    }

    const Section3 = () => {
        // const elements = [
        //     <Flex flexWrap={"wrap"} paddingTop={"100px"} paddingBottom={"50px"} justifyContent={"space-between"} paddingLeft={"55px"} paddingRight={"55px"} width={"100%"} style={{overflowX: "scroll", whiteSpace: "nowrap"}}>
        //         {(textsProjects.get(language) as ITextProjects).projects.map((el: {id:number, color: string, background: string, title: string, description: string}, index) => {
        //             return (
        //                 windowDimensions.wWCheck(1000) ?
        //                     <ProjectCard key={index} itemId={el.id.toString()} color={el.color} background={el.background} title={el.title} description={el.description} number={index}/>
        //                     :
        //                     <ProjectCardSmall key={index} itemId={el.id.toString()} color={el.color} background={el.background} title={el.title} description={el.description} number={index}/>
        //             )
        //         })}
        //     </Flex>
        // ]

        return (
            <Box>
                {/*<ScrollMenu*/}
                {/*    data={elements}*/}
                {/*    wheel={false}*/}
                {/*/>*/}
                {/*<Flex flexWrap={"nowrap"} overflowX={"auto"} paddingTop={"100px"} paddingBottom={"50px"} justifyContent={"space-between"} paddingLeft={"55px"} paddingRight={"55px"}>*/}
                {/*    {(textsProjects.get(language) as ITextProjects).projects.map((el: {id:number, color: string, background: string, title: string, description: string}, index) => {*/}
                {/*        return (*/}
                {/*            windowDimensions.wWCheck(1000) ?*/}
                {/*                <ProjectCard key={index} itemId={el.id.toString()} color={el.color} background={el.background} title={el.title} description={el.description} number={index}/>*/}
                {/*                :*/}
                {/*                <ProjectCardSmall key={index} itemId={el.id.toString()} color={el.color} background={el.background} title={el.title} description={el.description} number={index}/>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</Flex>*/}
                <div id={"container"} style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    marginTop: "100px",
                    marginBottom: "50px",
                    paddingLeft: windowDimensions.wWCheck(1000) ? "var(--PageMargin)" : "0px",
                    paddingRight: windowDimensions.wWCheck(1000) ? "var(--PageMargin)" : "0px",
                    justifyContent: "space-between"
                }}>
                    {(textsProjects.get(language) as ITextProjects).projects.map((el: { id: number, color: string, background: string, title: string, description: string }, index) => {
                        return (
                            windowDimensions.wWCheck(1000) ?
                                <ProjectCard key={index} itemId={el.id.toString()} color={el.color}
                                             background={el.background} title={el.title} description={el.description}
                                             number={index}/>
                                :
                                <ProjectCardSmall key={index} itemId={el.id.toString()} color={el.color}
                                                  background={el.background} title={el.title}
                                                  description={el.description} number={index}/>
                        )
                    })}
                </div>
            </Box>
        )
    }

    const Section4 = () => {
        return (
            windowDimensions.wWCheck(1000) ?
                <Flex flexDirection={"column"} marginLeft={"var(--PageMargin)"} marginRight={"var(--PageMargin)"}
                      marginBottom={"50px"} style={{position: "relative"}}>
                    <MyText id={"about"} value={(texts.get(language) as ITextHome).about} style={{
                        color: "var(--White)",
                        fontSize: "var(--SmallHL)",
                        marginBottom: "58px",
                        lineHeight: "96.5%",
                        letterSpacing: "-4%"
                    }}/>
                    <MyText value={(texts.get(language) as ITextHome).presentationTitle} style={{
                        color: "var(--White)",
                        fontSize: "var(--MediumHL)",
                        marginBottom: "93px",
                        width: windowDimensions.wWCheck(1350) ? "60%" : "70%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: "20px",
                        lineHeight: "100.5%",
                        letterSpacing: "-4%"
                    }}/>
                    <Flex marginTop={"40px"} alignItems={"center"}>
                        <MyImage src={ThomPicture} style={{width: "65%"}}/>
                        <Flex marginLeft={"12%"} width={"100%"}>
                            <Flex flexDirection={"column"} width={"50%"} marginRight={"50px"}>
                                <MyText value={(texts.get(language) as ITextHome).presentationParagraph1} style={{
                                    color: "var(--White)",
                                    fontSize: "var(--Small)",
                                    lineHeight: "100.5%",
                                    letterSpacing: "-4%"
                                }}/>
                                <MyText value={(texts.get(language) as ITextHome).presentationParagraph2} style={{
                                    color: "var(--White)",
                                    fontSize: "var(--Small)",
                                    marginTop: "20px",
                                    lineHeight: "100.5%",
                                    letterSpacing: "-4%"
                                }}/>
                            </Flex>
                            <Flex flexDirection={"column"} width={"50%"}>
                                <MyText value={(texts.get(language) as ITextHome).presentationParagraph3} style={{
                                    color: "var(--White)",
                                    fontSize: "var(--Small)",
                                    lineHeight: "100.5%",
                                    letterSpacing: "-4%"
                                }}/>
                                <MyText value={(texts.get(language) as ITextHome).presentationParagraph4} style={{
                                    color: "var(--White)",
                                    fontSize: "var(--Small)",
                                    marginTop: "20px",
                                    lineHeight: "100.5%",
                                    letterSpacing: "-4%"
                                }}/>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex marginTop={"30px"} style={{position: "absolute", right: "6%", bottom: "0"}}
                          onClick={() => window.location.href = ("mailto:" + (textsHeader.get(language) as ITextHeader).mailto)}>
                        <MyLink value={(texts.get(language) as ITextHome).contact} style={{
                            color: "var(--White)",
                            fontSize: "var(--SmallHL)",
                            textDecoration: "underline",
                            textUnderlineOffset: "5px",
                            lineHeight: "96.5%",
                            letterSpacing: "-4%"
                        }} styleHovered={{
                            color: "var(--White)",
                            fontSize: "var(--SmallHL)",
                            textDecoration: "underline",
                            textUnderlineOffset: "5px",
                            lineHeight: "96.5%",
                            letterSpacing: "-4%"
                        }}/>
                        <MyImage src={ArrowRight} style={{marginTop: "2px", marginLeft: "5px"}}/>
                    </Flex>
                </Flex>
                :
                <Flex flexDirection={"column"} marginBottom={"50px"}>
                    <MyText id={"about"} value={(texts.get(language) as ITextHome).about} style={{
                        color: "var(--White)",
                        fontSize: "var(--SmallHL)",
                        marginBottom: "40px",
                        lineHeight: "96.5%",
                        letterSpacing: "-4%",
                        marginLeft: "30px",
                        marginRight: "30px"
                    }}/>
                    <MyText value={(texts.get(language) as ITextHome).presentationTitle} style={{
                        color: "var(--White)",
                        fontSize: "var(--MediumHL)",
                        marginBottom: "75px",
                        lineHeight: "100.5%",
                        letterSpacing: "-4%",
                        marginLeft: "44px",
                        marginRight: "44px"
                    }}/>
                    <MyImage src={ThomPicture}
                             style={{width: "100%", minWidth: "270px", marginLeft: "auto", marginRight: "auto"}}/>
                    <Flex flexDirection={"column"} marginTop={"40px"} alignItems={"center"} marginLeft={"30px"}
                          marginRight={"30px"}>
                        <Flex flexDirection={"column"}>
                            <MyText value={(texts.get(language) as ITextHome).presentationParagraph1} style={{
                                color: "var(--White)",
                                fontSize: "var(--Small)",
                                lineHeight: "100.5%",
                                letterSpacing: "-4%"
                            }}/>
                            <MyText value={(texts.get(language) as ITextHome).presentationParagraph2} style={{
                                color: "var(--White)",
                                fontSize: "var(--Small)",
                                marginTop: "20px",
                                lineHeight: "100.5%",
                                letterSpacing: "-4%"
                            }}/>
                        </Flex>
                        <Flex flexDirection={"column"}>
                            <MyText value={(texts.get(language) as ITextHome).presentationParagraph3} style={{
                                color: "var(--White)",
                                fontSize: "var(--Small)",
                                marginTop: "20px",
                                lineHeight: "100.5%",
                                letterSpacing: "-4%"
                            }}/>
                            <MyText value={(texts.get(language) as ITextHome).presentationParagraph4} style={{
                                color: "var(--White)",
                                fontSize: "var(--Small)",
                                marginTop: "20px",
                                lineHeight: "100.5%",
                                letterSpacing: "-4%"
                            }}/>
                        </Flex>
                    </Flex>
                    <Flex marginTop={"50px"} marginLeft={"auto"} marginRight={"5%"}
                          onClick={() => window.location.href = ("mailto:" + (textsHeader.get(language) as ITextHeader).mailto)}>
                        <MyLink value={(texts.get(language) as ITextHome).contact} style={{
                            color: "var(--White)",
                            fontSize: "var(--SmallHL)",
                            textDecoration: "underline",
                            textUnderlineOffset: "2px",
                            lineHeight: "96.5%",
                            letterSpacing: "-4%"
                        }} styleHovered={{
                            color: "var(--White)",
                            fontSize: "var(--SmallHL)",
                            textDecoration: "underline",
                            textUnderlineOffset: "2px",
                            lineHeight: "96.5%",
                            letterSpacing: "-4%"
                        }}/>
                        <MyImage src={ArrowRight} style={{marginTop: "2px", marginLeft: "5px"}}/>
                    </Flex>
                </Flex>
        )
    }

    const Auth = () => {
        const [value, setValue] = useState("")

        return (
            <Flex flexDirection={"column"} width={"100%"} height={"100vh"} alignItems={"center"}
                  justifyContent={"center"} style={{zIndex: 1000}}>
                <MyInputText value={value} setValue={setValue} placeholder={"Password"} style={{background: "white"}}/>
                <MyButton value={"Validate"} styles={{marginTop: "20px"}} onClick={() => {
                    if (value.toLowerCase() === "thom") authContext.setAuth(true); else alert("Wrong password !")
                }}/>
            </Flex>
        )
    }

    return (
        <Flex flexDirection={"column"}>
            {/*{!authContext.auth && <Auth/>}*/}
            {/*{authContext.auth && <Flex flexDirection={"column"} backgroundColor={"var(--Grey)"} minHeight={"100vh"}>*/}
            {/*    <Section1/>*/}
            {/*    <Section2/>*/}
            {/*    <Section3/>*/}
            {/*    <Section4/>*/}
            {/*    <ArrowNavigation page={"home"}/>*/}
            {/*</Flex>}*/}
            <Flex flexDirection={"column"} backgroundColor={"var(--Grey)"} minHeight={"100vh"}>
                <Section1/>
                <Section2/>
                <Section3/>
                <Section4/>
                <ArrowNavigation page={"home"}/>
            </Flex>
        </Flex>
    );
}

export default Home;