import {createContext, useContext, useState} from "react";

const MenuContext = createContext(undefined as any);

type ProviderMenuT = {
    isOpen: boolean,
    isOverlay: boolean,
    descriptionAvailable: boolean,
    setDescriptionAvailable: (value: boolean) => void
    setIsOpen: (value: boolean) => void
    setIsOverlay: (value: boolean) => void
    toggleMenu: () => void
    toogleOverlay: () => void
}

export const useMenu = () : ProviderMenuT => {
    return useContext(MenuContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const MenuProvider = ({children}: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOverlay, setIsOverlay] = useState(false);
    const [descriptionAvailable, setDescriptionAvailable] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setIsOverlay(!isOverlay);
    }

    const toggleOverlay = () => {
        setIsOverlay(!isOverlay);
    }

    const value = {
        isOpen,
        isOverlay,
        descriptionAvailable,
        setDescriptionAvailable,
        setIsOpen,
        setIsOverlay,
        toggleMenu,
        toggleOverlay
    }

    return (
        <MenuContext.Provider value={value}>
            {children}
        </MenuContext.Provider>
    );
}

export default MenuProvider;
