import {createContext, useContext, useState} from "react";

const TextContext = createContext(undefined as any);

type ProviderTextT = {
    currentLanguage: string,
    setCurrentLanguage: (language: string) => void
    availableLanguages: string[],
}

export const useText = () :ProviderTextT => {
    return useContext(TextContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const TextProvider = ({children}: IProps) => {
    const [currentLanguage, setCurrentLanguage] = useState("en");
    const availableLanguages = ["en"];

    const value = {
        currentLanguage,
        setCurrentLanguage,
        availableLanguages
    }

    return (
        <TextContext.Provider value={value}>
            {children}
        </TextContext.Provider>
    );
}

export default TextProvider;
