import React from "react";
import { Text } from 'theme-ui'

interface Props {
    value: string | undefined
    style?: object;
    id?: string;
    as?: string;
    whiteSpace?: boolean;
    ref?: any;
    className?: string;
    onClick?: () => void;
}

export const MyText = (props: Props) => {
    return <Text as={props.as ? props.as as React.ElementType : "span"}
            sx={{
                color: "myBlack",
                fontSize: "var(--Small)",
                fontFamily: "myRegular",
                whiteSpace: props.whiteSpace ? "pre" : "normal"}}
            onClick={props.onClick}
            style={props.style} id={props.id} className={props.className} ref={props.ref ? props.ref : undefined}>
        {props.value}
    </Text>
};

export default MyText;
