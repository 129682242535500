export interface IText {
    first: string;
    second: string;
    third: string;
    title: string;
    subtitle: string;
    paragraph1: string;
    paragraph2: string;
    sentence: string;
    sentenceDescription: string;
    contact: string;
}

const texts: Map<string, IText> = new Map<string, IText>();

texts.set('en', {
    first: "friction /ˈfrɪkʃn/ noun [mass noun]",
    second: "Conflict or animosity caused by a clash of wills, temperaments, or opinions.",
    third: "friction . disturb . rub against . conflict . clash . discord",
    title: "Energy can neither be created nor destroyed; rather, it can only be transformed or transferred from one form to another.",
    subtitle: "The law of conservation of energy by Émilie du Châtelet",
    paragraph1: "According to the law of conservation of energy the total energy of an isolated system remains constant. Since our world, our planet is an isolated system, energy can not disappear, it can only move from one place to another. Therefore we need to make sure that we make the right and respectful choice when we move our energy from one place to another.",
    paragraph2: "By disturbing your comfort zone, by rubbing against your normal ways I create friction and this friction generates this electrifying energy between you, your organization and myself, and we will not let this energy go to waste. We will use it to rekindle your curiosity and instigate your journey towards a sustainable awareness and a better future.",
    sentence: "Did you know driving a Hummer is the more sustainable choice then driving a Prius. Let me tell you why over coffee.",
    sentenceDescription: "I'm not joking.",
    contact: "contact",
})

export default texts;