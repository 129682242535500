import React from "react";
import { Link } from 'theme-ui'

interface Props {
    value: string
    onClick?: () => void;
    style?: object;
    styleHovered?: object;
    ref?: (node?: (Element | null | undefined)) => void;
    id?: string;
    isHovered?: boolean
    hoveredColor?: string
    className?: string
}

export const MyLink = (props: Props) => {
    const [hovered, setHovered] = React.useState(false);

    return <Link
      id={props.id}
      ref={props.ref}
        sx={{
            color: props.isHovered ? props.hoveredColor ? props.hoveredColor : "myGreen" : "myBlack",
            fontSize: "var(--Small)",
            fontFamily: "myRegular",
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
                color: props.hoveredColor ? props.hoveredColor : "myGreen",
            }
        }}
        className={props.className}
        style={hovered ? props.styleHovered ? props.styleHovered : props.style : props.style}
        onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}} onClick={props.onClick}>
        {props.value}
    </Link>
};

export default MyLink;
