import React from "react";
import "./../assets/css/constants.css";
import {useText} from "../assets/contexts/textContext";
import texts, {IText} from '../assets/texts/notfound';
import {Flex} from 'rebass';
import MyText from "../components/basics/MyText";
import MyImage from "../components/basics/MyImage";
import MyLink from "../components/basics/MyLink";
import ArrowRight from "../assets/icons/arrowRight.svg";
import {useNavigate} from "react-router-dom";
import {useWindowDimensions} from "../assets/contexts/windowDimensionsContext";

const NotFound = () => {
    const language = useText().currentLanguage;
    const navigate = useNavigate();
    const windowDimensions = useWindowDimensions();

    return (
        <Flex flexDirection={"column"}>
            <Flex alignItems={"center"} justifyContent={"center"} flexDirection={"column"} backgroundColor={"var(--DarkGrey)"} minHeight={windowDimensions.windowHeight - 260} marginTop={"var(--HeaderHeight)"}>
                <MyText value={(texts.get(language) as IText).unfound} style={{color: "var(--White)", fontSize: "var(--PageTitleFontSize)", margin: "50px", textAlign: "center"}}/>
                <Flex marginTop={"30px"} marginBottom={"50px"} style={{cursor: "pointer"}} onClick={() => navigate("/")}>
                    <MyLink value={(texts.get(language) as IText).home} style={{color: "var(--White)", fontSize: "var(--MidFontSize)", textDecoration: "underline", textUnderlineOffset: "5px"}} styleHovered={{color: "var(--White)", fontSize: "var(--MidFontSize)", textDecoration: "underline", textUnderlineOffset: "5px"}}/>
                    <MyImage src={ArrowRight} style={{scale: "0.9", marginTop: "2px", marginLeft: "5px"}}/>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default NotFound;