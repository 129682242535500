import {createContext, useContext, useState} from "react";

const HomeContext = createContext(undefined as any);

type ProviderHomeT = {
    frictionClicked: boolean,
    setFrictionClicked: (value: boolean) => void
}

export const useHome = () : ProviderHomeT => {
    return useContext(HomeContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const HomeProvider = ({children}: IProps) => {
    const [frictionClicked, setFrictionClicked] = useState(false);

    const value = {
        frictionClicked,
        setFrictionClicked,
    }

    return (
        <HomeContext.Provider value={value}>
            {children}
        </HomeContext.Provider>
    );
}

export default HomeProvider;
