import React from "react";
import "../../assets/css/constants.css";
import {Flex} from 'rebass';
import {useText} from "../../assets/contexts/textContext";
import texts, {IText} from '../../assets/texts/arrowNatvigation';
import textsProjects, {ITextProjects} from '../../assets/texts/projects';
import MyText from "../basics/MyText";
import MyImage from "../basics/MyImage";
import ArrowUp2 from "../../assets/icons/arrowUp22t.png"
import ArrowLeft2 from "../../assets/icons/arrowLeft22t.png"
import ArrowRight2 from "../../assets/icons/arrowRight22t.png"
import Point from "../../assets/icons/point.png"
import Scroll from "react-scroll";
import {useNavigate, useSearchParams} from "react-router-dom";

interface Props {
    page: string;
}

const ArrowNavigation = (props: Props) => {
    const language = useText().currentLanguage;
    const scroll = Scroll.animateScroll;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const HomeNavigation = () => {
        return (
            <Flex flexDirection={"column"} alignItems={"center"} marginTop={"50px"} marginBottom={"75px"}>
                <MyText value={(texts.get(language) as IText).homeTop} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => scroll.scrollToTop()}/>
                <MyImage src={ArrowUp2} style={{height: "25px"}} onclick={() => scroll.scrollToTop()}/>
                {/*<MyImage src={Point} style={{marginTop: "-0.2px", height: "15px", transform: "translateY(-5px)"}}/>*/}
            </Flex>
        )
    }

    const FrictionNavigation = () => {
        return (
            // <Flex flexDirection={"column"} alignItems={"center"} marginTop={"50px"} marginBottom={"75px"}>
            //     <Flex flexDirection={"column"} alignItems={"center"}>
            //         <MyText value={(texts.get(language) as IText).homeTop} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => scroll.scrollToTop()}/>
            //         <MyImage src={ArrowUp2} style={{height: "25px"}} onclick={() => scroll.scrollToTop()}/>
            //     </Flex>
            //     <Flex flexDirection={"column"} alignItems={"center"} style={{position: "relative"}}>
            //         <MyImage src={ArrowLeft2} style={{height: "15px", transform: "translateY(-5px) translateX(-15px)"}} onclick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>
            //         <MyText value={Number(searchParams.get("id")) > 1 ? (texts.get(language) as IText).back : (texts.get(language) as IText).home} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", position: "absolute", top: "-8px", left: "-88px", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>
            //     </Flex>
            //     <MyImage src={Point} style={{marginTop: "-0.2px", height: "15px", transform: "translateY(-21px)"}}/>
            // </Flex>
            <Flex flexDirection={"column"} alignItems={"center"} marginTop={"50px"} marginBottom={"75px"}>
                <Flex flexDirection={"column"} alignItems={"center"}>
                    <MyText value={(texts.get(language) as IText).homeTop} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => scroll.scrollToTop()}/>
                    <MyImage src={ArrowUp2} style={{height: "25px"}} onclick={() => scroll.scrollToTop()}/>
                </Flex>
                <Flex flexDirection={"column"} alignItems={"center"} style={{position: "relative"}}>
                    <MyImage src={ArrowLeft2} style={{height: "15px", transform: "translateY(-7px) translateX(-11px)"}} onclick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>
                    <MyText value={Number(searchParams.get("id")) > 1 ? (texts.get(language) as IText).back : (texts.get(language) as IText).home} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", position: "absolute", top: "-10px", left: "-88px", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>
                </Flex>
            </Flex>
        )
    }

    const ProjectDescriptionNavigation = () => {
        return (
            // <Flex flexDirection={"column"} alignItems={"center"} marginTop={"50px"} marginBottom={"75px"}>
            //     <Flex flexDirection={"column"} alignItems={"center"}>
            //         <MyText value={(texts.get(language) as IText).homeTop} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => scroll.scrollToTop()}/>
            //         <MyImage src={ArrowUp2} style={{height: "25px"}} onclick={() => scroll.scrollToTop()}/>
            //     </Flex>
            //     <Flex flexDirection={"column"} alignItems={"center"} style={{position: "relative"}}>
            //         <MyImage src={ArrowLeft2} style={{height: "15px", transform: "translateY(-5px) translateX(-15px)"}} onclick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>
            //         <MyText value={Number(searchParams.get("id")) > 1 ? (texts.get(language) as IText).back : (texts.get(language) as IText).home} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", position: "absolute", top: "-8px", left: "-88px", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>
            //     </Flex>
            //     {((textsProjects.get(language) as ITextProjects).projects.length > 1 && Number(searchParams.get("id")) < (textsProjects.get(language) as ITextProjects).projects.length) && <Flex flexDirection={"column"} alignItems={"center"} style={{position: "relative"}}>
            //         <MyText value={(texts.get(language) as IText).next} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", position: "absolute", top: "-24px", left: "35px", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+(Number(searchParams.get("id"))+1))}}/>
            //         <MyImage src={ArrowRight2} style={{marginTop: "-0.2px", height: "15px", transform: "translateY(-20px) translateX(15px)"}} onclick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+(Number(searchParams.get("id"))+1))}}/>
            //     </Flex>}
            //     <MyImage src={Point} style={{marginTop: "-0.2px", height: "15px", transform: ((textsProjects.get(language) as ITextProjects).projects.length > 1 && Number(searchParams.get("id")) < (textsProjects.get(language) as ITextProjects).projects.length) ? "translateY(-36px)" : "translateY(-21px)"}}/>
            // </Flex>
            <Flex flexDirection={"column"} alignItems={"center"} marginTop={"50px"} marginBottom={"75px"}>
                <Flex flexDirection={"column"} alignItems={"center"}>
                    <MyText value={(texts.get(language) as IText).homeTop} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => scroll.scrollToTop()}/>
                    <MyImage src={ArrowUp2} style={{height: "25px"}} onclick={() => scroll.scrollToTop()}/>
                </Flex>
                {/*<Flex alignItems={"center"} style={{position: "relative"}}>*/}
                {/*    <MyText value={Number(searchParams.get("id")) > 1 ? (texts.get(language) as IText).back : (texts.get(language) as IText).home} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>*/}
                {/*    <MyImage src={ArrowLeft2} style={{height: "15px"}} onclick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>*/}
                {/*    <MyImage src={ArrowRight2} style={{height: "15px"}} onclick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+(Number(searchParams.get("id"))+1))}}/>*/}
                {/*    <MyText value={(texts.get(language) as IText).next} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+(Number(searchParams.get("id"))+1))}}/>*/}
                {/*</Flex>*/}
                <Flex flexDirection={"column"} alignItems={"center"} style={{position: "relative"}}>
                    <MyImage src={ArrowLeft2} style={{height: "15px", transform: "translateY(-7px) translateX(-11px)"}} onclick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>
                    <MyText value={Number(searchParams.get("id")) > 1 ? (texts.get(language) as IText).back : (texts.get(language) as IText).home} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", position: "absolute", top: "-10px", left: "-88px", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => {scroll.scrollToTop({duration: 0}); Number(searchParams.get("id")) > 1 ? navigate("/project-description?id="+(Number(searchParams.get("id"))-1)) : navigate("/")}}/>
                </Flex>
                {((textsProjects.get(language) as ITextProjects).projects.length > 1 && Number(searchParams.get("id")) < (textsProjects.get(language) as ITextProjects).projects.length) && <Flex flexDirection={"column"} alignItems={"center"} style={{position: "relative"}}>
                    <MyText value={(texts.get(language) as IText).next} style={{color: "var(--White)", fontSize: "20px", marginBottom: "10px", width: "70px", textAlign: "center", position: "absolute", top: "-26px", left: "35px", cursor: "pointer", lineHeight: "96.5%", letterSpacing: "-4%"}} onClick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+(Number(searchParams.get("id"))+1))}}/>
                    <MyImage src={ArrowRight2} style={{height: "15px", transform: "translateY(-22px) translateX(12px)"}} onclick={() => {scroll.scrollToTop({duration: 0}); navigate("/project-description?id="+(Number(searchParams.get("id"))+1))}}/>
                </Flex>}
            </Flex>
        )
    }

    return (
        <Flex flexDirection={"column"}>
            {props.page === "home" && <HomeNavigation/>}
            {props.page === "friction" && <FrictionNavigation/>}
            {props.page === "project-description" && <ProjectDescriptionNavigation/>}
        </Flex>
    );
}

export default ArrowNavigation;