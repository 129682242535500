export interface IText {
    linkedin: string;
    instagram: string;
    linkedinLink: string;
    instagramLink: string;
    year: string;
}

const texts: Map<string, IText> = new Map<string, IText>();

texts.set('en', {
    linkedin: "Linkedin",
    instagram: "Instagram",
    linkedinLink: "https://www.linkedin.com/in/thom-schrama/",
    instagramLink: "https://www.instagram.com/thomschrama/",
    year: "©2023",
})

export default texts;