export interface ITextHeader {
    home: string;
    about: string;
    projects: string;
    friction: string;
    contact: string;
    mailto: string;
}

const texts: Map<string, ITextHeader> = new Map<string, ITextHeader>();

texts.set('en', {
    home: "home",
    about: "about",
    projects: "projects",
    friction: "friction",
    contact: "contact",
    mailto: "mail@thomrobinschrama.com"
})

export default texts;