import {Route, Routes, useLocation} from "react-router-dom";
import NotFound from "./pages/notFound";
import Home from "./pages/home";
import Friction from "./pages/friction";
import ProjectDescription from "./pages/projectDescription";
import Counter from "./pages/counter";

const MyRoutes = () => {
    const location = useLocation()

    return (
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home/>}/>
            <Route path="/friction" element={<Friction/>}/>
            <Route path="/project-description" element={<ProjectDescription/>}/>
            <Route path="/counter" element={<Counter/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}

export default MyRoutes;