import React, {useEffect, useState} from "react";
import "./../assets/css/constants.css";
import {useText} from "../assets/contexts/textContext";
import texts, {ITextProjects} from '../assets/texts/projects';
import textsCounter, {ITextCounter} from '../assets/texts/counter';
import {ISections} from '../assets/texts/projects';
import {Box, Flex} from 'rebass';
import {useSearchParams} from "react-router-dom";
import MyImage from "../components/basics/MyImage";
import MyText from "../components/basics/MyText";
import ArrowNavigation from "../components/navigation/arrowNavigation";
import {useWindowDimensions} from "../assets/contexts/windowDimensionsContext";
import countapi from "countapi-js";
import {useMenu} from "../assets/contexts/MenuContext";

interface IGenerateSection {
    section: ISections
}

const ProjectDescription = () => {
    const language = useText().currentLanguage;
    const [projectId, setProjectId] = useState("");
    const [searchParams] = useSearchParams();
    const windowDimensions = useWindowDimensions();
    const menuContext = useMenu();

    useEffect(() => {
        const tmp = searchParams.get("id");

        if (tmp)
            setProjectId(tmp);
    }, [window.location.href])

    useEffect(()=> {
        if (projectId !== "") {
            let key = (textsCounter.get(language) as ITextCounter).countProjectsKeys.at(Number(projectId) - 1) as string

            countapi.get("counterthomschramap"+searchParams.get("id")+".com", key).then((result) => {countapi.set("counterthomschramap"+searchParams.get("id")+".com", key, Number(result.value + 1)).then((result) => {  })});
        }
    },[projectId])

    const Text = (props: {text: {title: boolean, text: string, color?: string, width?: string, height?: string, margin?: string, colorSmall?: string, widthSmall?: string, heightSmall?: string, marginSmall?: string}}) => {
        const projectTitle = props.text.text.includes("#projectTitle") ? props.text.text.split(" ").splice(1) : []

        return (
            props.text.text.includes("#projectTitle") ?
                <Flex style={{position: "absolute", top: "60px", left: "20px"}} flexDirection={"column"} margin={props.text.margin ? props.text.margin : "40px"}>
                    {projectTitle.map((title, index) => {
                        return (
                            index % 2 === 0 ?
                                <Flex>
                                    <MyText value={projectTitle.at(index)} style={{color: props.text.color ? props.text.color : "var(--White)", fontSize: props.text.title ? "var(--PageTitleFontSize)" : "var(--Small)", whiteSpace: "pre-wrap", margin: "2px 0 0 0", width: "100px"}}/>
                                    <MyText value={projectTitle.at(index+1)} style={{color: props.text.color ? props.text.color : "var(--White)", fontSize: props.text.title ? "var(--PageTitleFontSize)" : "var(--Small)", whiteSpace: "pre-wrap", fontFamily: "Inter-Light"}}/>
                                </Flex>
                                :
                                null
                        )
                    })}
                </Flex>
                :
                <MyText value={props.text.text} style={{color: props.text.color ? props.text.color : "var(--White)", fontSize: props.text.title ? "var(--PageTitleFontSize)" : "var(--Small)", margin: props.text.margin ? props.text.margin : props.text.title ? "40px" : "7px", fontFamily: props.text.title ? "Inter-Regular" : "Inter-Light", width: props.text.width ? props.text.width : "50%", height: props.text.height ? props.text.height : "", whiteSpace: "pre-wrap"}}/>
        )
    }

    const TextSmall = (props: {text: {title: boolean, text: string, color?: string, width?: string, height?: string, margin?: string, colorSmall?: string, widthSmall?: string, heightSmall?: string, marginSmall?: string}}) => {
        const projectTitle = props.text.text.includes("#projectTitle") ? props.text.text.split(" ").splice(1) : []

        return (
            props.text.text.includes("#projectTitle") ?
                <Flex flexDirection={"column"} margin={props.text.marginSmall ? props.text.marginSmall : "40px"}>
                    {projectTitle.map((title, index) => {
                        return (
                            index % 2 === 0 ?
                                <Flex style={{position: "relative"}}>
                                    <MyText value={projectTitle.at(index)} style={{color: props.text.colorSmall ? props.text.colorSmall : props.text.color ? props.text.color : "var(--White)", fontSize: props.text.title ? "var(--PageTitleFontSize)" : "var(--Small)", whiteSpace: "pre-wrap", margin: "2px 0 0 0", width: "100px"}}/>
                                    <MyText value={projectTitle.at(index+1)} style={{color: props.text.colorSmall ? props.text.colorSmall : props.text.color ? props.text.color : "var(--White)", fontSize: props.text.title ? "var(--PageTitleFontSize)" : "var(--Small)", whiteSpace: "pre-wrap", fontFamily: "Inter-Light"}}/>
                                </Flex>
                                :
                                null
                        )
                    })}
                </Flex>
                :
                <MyText value={props.text.text} style={{color: props.text.colorSmall ? props.text.colorSmall : props.text.color ? props.text.color : "var(--White)", fontSize: props.text.title ? "var(--PageTitleFontSize)" : "var(--Small)", margin: props.text.marginSmall ? props.text.marginSmall : "30px 30px 30px 30px", fontFamily: props.text.title ? "Inter-Regular" : "Inter-Light", height: props.text.heightSmall ? props.text.heightSmall : "", whiteSpace: "pre-wrap"}}/>
        )
    }

    const ModelTextText = (props: IGenerateSection) => {
        return (
            <Flex width={props.section.width} height={props.section.height} margin={props.section.margin} backgroundColor={props.section.backgroundColor}>
                <Flex width={"50%"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"} style={{position: "relative"}}>
                    {props.section.text1 && props.section.text1.map((text, index) => {
                        return (
                            <Text key={index} text={text}/>
                        )
                    })}
                </Flex>
                <Flex width={"50%"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                    {props.section.text2 && props.section.text2.map((text, index) => {
                        return (
                            <Text key={index} text={text}/>
                        )
                    })}
                </Flex>
            </Flex>
        )
    }

    const ModelTextTextSmall = (props: IGenerateSection) => {
        return (
            <Flex flexDirection={"column"} width={props.section.widthSmall} height={props.section.heightSmall} margin={props.section.marginSmall} backgroundColor={props.section.backgroundColorSmall ? props.section.backgroundColorSmall : props.section.backgroundColor}>
                <Flex flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"} style={{position: "relative"}}>
                    {props.section.text1 && props.section.text1.map((text, index) => {
                        return (
                            <TextSmall key={index} text={text}/>
                        )
                    })}
                </Flex>
                <Flex flexWrap={"wrap"}>
                    {props.section.text2 && props.section.text2.map((text, index) => {
                        return (
                            <TextSmall key={index} text={text}/>
                        )
                    })}
                </Flex>
            </Flex>
        )
    }

    const ModelTextImage = (props: IGenerateSection) => {
        return (
            <Flex width={props.section.width} height={props.section.height} margin={props.section.margin} backgroundColor={props.section.backgroundColor}>
                <Flex width={"50%"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    {props.section.text1 && props.section.text1.map((text, index) => {
                        return (
                            <Text key={index} text={text}/>
                        )
                    })}
                    <Box width={"25%"} height={"1px"} marginTop={"50px"} marginBottom={"75px"} marginLeft={"25%"} marginRight={"auto"} backgroundColor={props.section.text1?.at(0)?.color ? props.section.text1?.at(0)?.color : "var(--White)"}/>
                </Flex>
                {props.section.picture1?.name.includes(".mp4") || props.section.picture1?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} autoPlay={true}
                           loop={true} style={{
                        width: "100%",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} style={{
                        width: props.section.picture1?.width ? props.section.picture1?.width : "50%",
                        height: props.section.picture1?.height ? props.section.picture1?.height.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture1?.height.match(/\d+/)?.at(0)) + "px" : props.section.picture1?.height : "auto",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        objectFit: props.section.picture1?.objectFit ? props.section.picture1?.objectFit : "contain",
                        minHeight: "400px"
                    }}/>
                }
            </Flex>
        )
    }

    const ModelTextImageSmall = (props: IGenerateSection) => {
        return (
            <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                <Flex flexDirection={"column"} width={props.section.widthSmall} height={props.section.heightSmall} margin={props.section.marginSmall} backgroundColor={props.section.backgroundColorSmall ? props.section.backgroundColorSmall : props.section.backgroundColor}>
                    {props.section.text1 && props.section.text1.map((text, index) => {
                        return (
                            <TextSmall key={index} text={text}/>
                        )
                    })}
                    <Box width={"25%"} height={"1px"} marginTop={"50px"} marginBottom={"75px"} marginLeft={"30px"} marginRight={"auto"} backgroundColor={props.section.text1?.at(0)?.color ? props.section.text1?.at(0)?.color : "var(--White)"}/>
                </Flex>
                {props.section.picture1?.name.includes(".mp4") || props.section.picture1?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} autoPlay={true}
                           loop={true} style={{
                        width: "100%",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} style={{
                        width: props.section.picture1?.widthSmall ? props.section.picture1?.widthSmall : "60%",
                        height: props.section.picture1?.heightSmall ? props.section.picture1?.heightSmall.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture1?.heightSmall.match(/\d+/)?.at(0)) + "px" : props.section.picture1?.heightSmall : "auto",
                        margin: props.section.picture1?.marginSmall ? props.section.picture1?.marginSmall : "",
                        borderRadius: props.section.picture1?.radiusSmall ? props.section.picture1?.radiusSmall : "",
                        objectFit: props.section.picture1?.objectFitSmall ? props.section.picture1?.objectFitSmall : "contain",
                        minHeight: "400px"
                    }}/>
                }
            </Flex>
        )
    }

    const ModelImageText = (props: IGenerateSection) => {
        return (
            <Flex width={props.section.width} height={props.section.height} margin={props.section.margin} backgroundColor={props.section.backgroundColor}>
                {props.section.picture1?.name.includes(".mp4") || props.section.picture1?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} autoPlay={true}
                           loop={true} style={{
                        width: "100%",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} style={{
                        width: props.section.picture1?.width ? props.section.picture1?.width : "50%",
                        height: props.section.picture1?.height ? props.section.picture1?.height.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture1?.height.match(/\d+/)?.at(0)) + "px" : props.section.picture1?.height : "auto",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        objectFit: props.section.picture1?.objectFit ? props.section.picture1?.objectFit : "contain",
                        minHeight: "400px"
                    }}/>
                }
                <Flex width={"50%"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    {props.section.text1 && props.section.text1.map((text, index) => {
                        return (
                            <Text key={index} text={text}/>
                        )
                    })}
                    <Box width={"25%"} height={"1px"} marginTop={"50px"} marginBottom={"75px"} marginLeft={"25%"} marginRight={"auto"} backgroundColor={props.section.text1?.at(0)?.color ? props.section.text1?.at(0)?.color : "var(--White)"}/>
                </Flex>
            </Flex>
        )
    }

    const ModelImageTextSmall = (props: IGenerateSection) => {
        return (
            <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                {props.section.picture1?.name.includes(".mp4") || props.section.picture1?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} autoPlay={true}
                           loop={true} style={{
                        width: "100%",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} style={{
                        width: props.section.picture1?.widthSmall ? props.section.picture1?.widthSmall : "60%",
                        height: props.section.picture1?.heightSmall ? props.section.picture1?.heightSmall.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture1?.heightSmall.match(/\d+/)?.at(0)) + "px" : props.section.picture1?.heightSmall : "auto",
                        margin: props.section.picture1?.marginSmall ? props.section.picture1?.marginSmall : "",
                        borderRadius: props.section.picture1?.radiusSmall ? props.section.picture1?.radiusSmall : "",
                        objectFit: props.section.picture1?.objectFitSmall ? props.section.picture1?.objectFitSmall : "contain",
                        minHeight: "400px"
                    }}/>
                }
                <Flex flexDirection={"column"} width={props.section.widthSmall} height={props.section.heightSmall} margin={props.section.marginSmall} backgroundColor={props.section.backgroundColorSmall ? props.section.backgroundColorSmall : props.section.backgroundColor}>
                    {props.section.text1 && props.section.text1.map((text, index) => {
                        return (
                            <TextSmall key={index} text={text}/>
                        )
                    })}
                    <Box width={"25%"} height={"1px"} marginTop={"50px"} marginBottom={"75px"} marginLeft={"30px"} marginRight={"auto"} backgroundColor={props.section.text1?.at(0)?.color ? props.section.text1?.at(0)?.color : "var(--White)"}/>
                </Flex>
            </Flex>
        )
    }

    const ModelImageImage = (props: IGenerateSection) => {
        return (
            <Flex width={props.section.width} height={props.section.height} margin={props.section.margin} backgroundColor={props.section.backgroundColor}>
                {props.section.picture1?.name.includes(".mp4") || props.section.picture1?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} autoPlay={true}
                           loop={true} style={{
                        width: "100%",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} style={{
                        width: props.section.picture1?.width ? props.section.picture1?.width : "50%",
                        height: props.section.picture1?.height ? props.section.picture1?.height.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture1?.height.match(/\d+/)?.at(0)) + "px" : props.section.picture1?.height : "auto",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        objectFit: props.section.picture1?.objectFit ? props.section.picture1?.objectFit : "contain",
                        minHeight: "400px"
                    }}/>
                }
                {props.section.picture2?.name.includes(".mp4") || props.section.picture2?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture2?.name} autoPlay={true}
                           loop={true} style={{
                        width: "100%",
                        margin: props.section.picture2?.margin ? props.section.picture2?.margin : "",
                        borderRadius: props.section.picture2?.radius ? props.section.picture2?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/"+props.section.picture2?.name} style={{width: props.section.picture2?.width ? props.section.picture2?.width : "50%", height: props.section.picture2?.height ? props.section.picture2?.height.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture2?.height.match(/\d+/)?.at(0)) + "px" : props.section.picture2?.height : "auto", margin: props.section.picture2?.margin ? props.section.picture2?.margin : "", borderRadius: props.section.picture2?.radius ? props.section.picture2?.radius : "", objectFit: props.section.picture2?.objectFit ? props.section.picture2?.objectFit : "contain", minHeight: "400px"}}/>
                }
            </Flex>
        )
    }

    const ModelImageImageSmall = (props: IGenerateSection) => {
        return (
            <Flex flexDirection={"column"} width={props.section.width} height={props.section.height} margin={props.section.margin} backgroundColor={props.section.backgroundColor}>
                {props.section.picture1?.name.includes(".mp4") || props.section.picture1?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} autoPlay={true}
                           loop={true} style={{
                        width: "100%",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} style={{
                        width: props.section.picture1?.width ? props.section.picture1?.width : "100%",
                        height: props.section.picture1?.height ? props.section.picture1?.height.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture1?.height.match(/\d+/)?.at(0)) + "px" : props.section.picture1?.height : "auto",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        objectFit: props.section.picture1?.objectFit ? props.section.picture1?.objectFit : "contain",
                        minHeight: "400px"
                    }}/>
                }
                {props.section.picture2?.name.includes(".mp4") || props.section.picture2?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture2?.name} autoPlay={true}
                           loop={true} style={{
                        width: "100%",
                        margin: props.section.picture2?.margin ? props.section.picture2?.margin : "",
                        borderRadius: props.section.picture2?.radius ? props.section.picture2?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/"+props.section.picture2?.name} style={{width: props.section.picture2?.width ? props.section.picture2?.width : "100%", height: props.section.picture2?.height ? props.section.picture2?.height.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture2?.height.match(/\d+/)?.at(0)) + "px" : props.section.picture2?.height : "auto", margin: props.section.picture2?.margin ? props.section.picture2?.margin : "", borderRadius: props.section.picture2?.radius ? props.section.picture2?.radius : "", objectFit: props.section.picture2?.objectFit ? props.section.picture2?.objectFit : "contain", minHeight: "400px"}}/>
                }
            </Flex>
        )
    }

    const ModelImage = (props: IGenerateSection) => {
        return (
            <Flex width={props.section.width} height={props.section.height} margin={props.section.margin} backgroundColor={props.section.backgroundColor}>
                {props.section.picture1?.name.includes(".mp4") || props.section.picture1?.name.includes(".mov") ?
                    <video src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} autoPlay={true} loop={true} style={{
                        width: "100%",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        minHeight: "400px"
                    }}/>
                    :
                    <MyImage src={"/images/projects/descriptionPictures/" + props.section.picture1?.name} style={{
                        width: props.section.picture1?.width ? props.section.picture1?.width : "100%",
                        height: props.section.picture1?.height ? props.section.picture1?.height.includes("adjust") ? windowDimensions.windowHeight - Number(props.section.picture1?.height.match(/\d+/)?.at(0)) + "px" : props.section.picture1?.height : "auto",
                        margin: props.section.picture1?.margin ? props.section.picture1?.margin : "",
                        borderRadius: props.section.picture1?.radius ? props.section.picture1?.radius : "",
                        objectFit: props.section.picture1?.objectFit ? props.section.picture1?.objectFit : "contain",
                        minHeight: "400px"
                    }}/>
                }
            </Flex>
        )
    }

    const GenerateSection = (props: IGenerateSection) => {
        const changeToSmall = 800;

        menuContext.setDescriptionAvailable(true)

        switch(props.section.model) {
            case "image":
                return (
                    props.section.picture1?.onMobile === false ?
                        null
                            :
                        <ModelImage section={props.section}/>
                )
            case "image-image":
                return (
                    windowDimensions.wWCheck(changeToSmall) ?
                        <ModelImageImage section={props.section}/>
                        :
                        <ModelImageImageSmall section={props.section}/>
                )
            case "image-text":
                return (
                    windowDimensions.wWCheck(changeToSmall) ?
                        <ModelImageText section={props.section}/>
                        :
                        <ModelImageTextSmall section={props.section}/>

                )
            case "text-image":
                return (
                    windowDimensions.wWCheck(changeToSmall) ?
                        <ModelTextImage section={props.section}/>
                        :
                        <ModelTextImageSmall section={props.section}/>
                )
            case "text-text":
                return (
                    windowDimensions.wWCheck(changeToSmall) ?
                        <ModelTextText section={props.section}/>
                        :
                        <ModelTextTextSmall section={props.section}/>
                )
            default:
                return null;
        }
    }

    return (
        <Flex flexDirection={"column"}>
            <Flex flexDirection={"column"} backgroundColor={"var(--Grey)"} minHeight={"83.75vh"}>
                {(texts.get(language) as ITextProjects).description.map((description) => {
                    if (description.id === Number(projectId)) {
                        return (
                            description.sections.map((section: ISections) => {
                                return (
                                    <GenerateSection section={section}/>
                                )
                            })
                        )
                    }
                })}
                {!menuContext.descriptionAvailable && <Flex alignItems={"center"} justifyContent={"center"} marginTop={"var(--HeaderHeight)"} minHeight={"200px"}>
                    <MyText value={(texts.get(language) as ITextProjects).noProjectDescription} style={{color: "var(--White)", fontSize: "var(--PageTitleFontSize)", marginLeft: "30px", marginRight: "30px"}}/>
                </Flex>}
                <ArrowNavigation page={"project-description"}/>
            </Flex>
        </Flex>
    );
}

export default ProjectDescription;