import "../css/constants.css"

const projects =
    [
        // PROJECT 1
        {id: 1, color: "var(--White)", background: "transparent", title: "How to keep my data my data.", description: "How the sensor systems of the municipality of Amsterdam are threatening the right for privacy in public spaces."},
        // PROJECT 2
        {id: 2, color: "var(--Black)", background: "transparent", title: "Circular Bicycle Lights.", description: "Creating the winning design in a ciruclar design challenge for Swapfiets."},
        // PROJECT 3
        {id: 3, color: "var(--Black)", background: "transparent", title: "Reversed Engineering.", description: "Innovating electrical products to be ready for the new standards of today. Rebuilding life lasting products from scratch."},
        // PROJECT 4
        {id: 4, color: "var(--Black)", background: "transparent", title: "Modern Work Essential.", description: "Switch between direct and focussed light for working situations and an indirect glow for moments of relaxation."},
    ]

const projectDescription =
    // DESCRIPTION MODELS
    //      "image-image"
    //      "image-text"
    //      "text-image"
    //      "text-text"
    //      "image"
    [
        // PROJECT 1
        {id: 1,
            sections: [
                // SECTION 1
                {
                    model: "image",
                    picture1: {name: "Project1-1.jpg", height: "adjust 100", objectFit: "cover", onMobile: true}
                },
                // SECTION 2
                {
                    model: "text-text",
                    backgroundColor: "white",
                    text1: [
                        {title: false, color: "var(--Black)", marginSmall: "75px auto 0 30px", text: "#projectTitle Project ANOMA Year 2022 Client GEMEENTE\nAMSTERDAM"},
                    ],
                    text2: [
                        {title: false, color: "var(--Black)", width: "30%", widthSmall: "100%", margin: "100px auto 0 7%", marginSmall: "30px 30px 0 30px", text: "How the sensor systems of the municipality of Amsterdam are threatening the right for privacy in public spaces."},
                        {title: true, color: "var(--Black)", width: "40%", widthSmall: "100%", margin: "150px 45% 50px 0", marginSmall: "30px 30px 30px 30px", text: "How to keep my data my data."},
                        {title: false, color: "var(--Black)", width: "40%", widthSmall: "100%", margin: "0 2.5% 100px 0", marginSmall: "0 30px 0 30px", text: "The Dutch are very proud of their freedom, some even say it flows through their blood. Freedom is a constitutional right in The Netherlands, the freedom to express your opinion, the freedom of religion and the freedom of sexuality. The Netherlands was the first country in the world to legalise same sex marriage. The pinnacle of this claimed freedom is the capital Amsterdam. It is the city of diversity, the city that has hosted the Canal Pride since 1996."},
                        {title: false, color: "var(--Black)", width: "40%",widthSmall: "100%", margin: "0 0 auto 2.5%", marginSmall: "15px 30px 75px 30px", text: "But how is it possible that a city that waves the flags of freedom is using a system that threatens thisxact freedom of their citizens? The censoring system of the municipality of Amsterdam is counting just under 2000 active sensors, from which 1236 are optical sensors, cameras that are collecting personal data 24/7."},
                    ]
                },
                // SECTION 3
                {
                    model: "image-image",
                    picture1: {name: "Project1-2.jpg", height: "650px", objectFit: "cover"},
                    picture2: {name: "Project1-3.jpg", height: "650px", objectFit: "cover"},
                },
                // SECTION 4
                {
                    model: "text-text",
                    text1: [
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 2.5% 150px 0", marginSmall: "75px 30px 0 30px", text: "Many people claim they have nothing to hide, more than 60% of the people I interviewed did not see the problem with the government collecting personal data without their approval. But this thought is naive, and not correct. Most people are not even aware of the data the sensor is collecting, who is responsible for keeping that data safe and what is being done with this data. So how can you judge that it’s okay for someone to collect that data of you ?"},
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 0 auto 2.5%", marginSmall: "15px 30px 0 30px", text: "Others claim that they trust the government, they don’t see a threat in the government collecting their data. But it seems that people forget that power and governments can change.\n\nIn America, the data of millions of people was bought and used to influence the presidential elections. On the 24th of February 2022 Russia attacked Ukraine and war arrived within the borders of Europe."},
                    ],
                    text2: [
                        {title: true, widthSmall: "100%", margin: "150px 0 150px 0", marginSmall: "50px 30px 15px 30px", text: "Times change, and so do systems. That is why we have to defend ourselves against potentially dangerous systems before it is too late."},
                    ]
                },
                // SECTION 5
                {
                    model: "image",
                    picture1: {name: "Project1-4.jpg", height: "adjust 100", objectFit: "cover"},
                },
                // SECTION 6
                {
                    model: "image-text",
                    picture1: {name: "Project1-5.jpg", margin: "5% 0 5% 0", width: "40%", widthSmall: "100%", marginSmall: "0 0 0 0", objectFit: "cover"},
                    text1: [
                        {title: true, widthSmall: "100%", margin: "10% 30px 30px 30px", marginSmall: "20px 30px 0 30px", text: "My proposal: Anoma. An independent organisation that is fighting on a political level for data protection and the right for privacy in public spaces."},
                        {title: false, widthSmall: "100%", marginSmall: "30px 30px 0 30px", text: "With the slogan 'FREEDOM BY ANONYMITY - EVEN IN CHANGING TIMES’ Project Anoma wants to draw attention to a hard-fought right of all Dutch, freedom. This freedom is at stake in public spaces through the processing of personal data by, among others, the municipality Amsterdam."},
                        {title: false, widthSmall: "100%", marginSmall: "30px 30px 0 30px", text: "The Project is committed to get rid of all sensors in public spaces or if necessary to be replaced by anonymous sensors to ensure anonymity in public spaces. In addition, the organisation investigates other systems in which personal data of citizens are processed in order for potentially hazardous systems to intercept and adapt. Project Anoma creates a safe present and a secure future."},
                        {title: false, widthSmall: "100%", marginSmall: "30px 30px 0 30px", text: "Anoma disrupts the current system and instigates the conversation with the municipality, the government and its citizens."},
                    ]
                },
                // SECTION 7
                {
                    model: "image",
                    picture1: {name: "Project1-6.jpg", height: "adjust 100", objectFit: "cover"},
                },
                // SECTION 8
                {
                    model: "image-text",
                    picture1: {name: "Project1-7.png", width: "26%", margin: "5% 15% 5% auto", marginSmall: "2% 0 10% 0"},
                    text1: [
                        {title: true, widthSmall: "100%", margin: "15% 0 30px 0", marginSmall: "15% 30px 0 30px", text: "Personal Struggle"},
                        {title: false, widthSmall: "100%", marginSmall: "30px 30px 0 30px", text: "During the first chapters of this project I asked myself if I wanted to work on this assignment, and if I decided to not follow the briefing, how I would design my project."},
                        {title: false, widthSmall: "100%", marginSmall: "15px 30px 0 30px", text: "After an ethical research I decided to not work on the assigned request handed out by the municipality of Amsterdam. The reason being that I both as a person and a designer disagree with the system that is currently in place."},
                        {title: false, widthSmall: "100%", marginSmall: "15px 30px 0 30px", text: "The active system is collecting personal data through 1200 out of the 1869 active sensors. On top of that the system is built in a way where it is sensitive to hacking and can be misused by the municipality or the government in times to come."},
                        {title: false, widthSmall: "100%", marginSmall: "15px 30px 0 30px", text: "From this position I wrote an advice towards the municipality of Amsterdam where I plea for a total removal of all sensors collecting personal data and when the municipality had the need to collect data to do this with anonymous sensors."},
                    ]
                },
            ]
        },
         // PROJECT 2
         {id: 2,
            sections: [
                // SECTION 1
                {
                    model: "image",
                    picture1: {name: "Project2-1.jpg", height: "adjust 100", objectFit: "cover", onMobile: true}
                },
                // SECTION 2
                {
                    model: "text-text",
                    backgroundColor: "white",
                    text1: [
                        {title: false, color: "var(--Black)", marginSmall: "75px auto 0 30px", text: "#projectTitle Project REBATE Year 2022 Client SWAPFIETS"},
                    ],
                    text2: [
                        {title: false, color: "var(--Black)", width: "30%", widthSmall: "100%", margin: "100px auto 0 7%", marginSmall: "30px 30px 0 30px", text: "The future looks bright with the case of circular bike lights."},
                        {title: true, color: "var(--Black)", width: "40%", widthSmall: "100%", margin: "150px 45% 50px 0", marginSmall: "30px 30px 30px 30px", text: "100% circular bicycle lights"},
                        {title: false, color: "var(--Black)", width: "40%", widthSmall: "100%", margin: "0 2.5% 100px 0", marginSmall: "0 30px 0 30px", text: "Swapfiets has the ambitious goal of a 100% circular product by 2025. To achieve this, they have to take an in depth look at every aspect of their product. The good news is: their circular business model helps them with this goal.\n\nThanks to the fact they retain ownership over their products, they are supplied with a high volume of data. This gives them great insights to create a future-proof product."},
                        {title: false, color: "var(--Black)", width: "40%",widthSmall: "100%", margin: "0 0 auto 2.5%", marginSmall: "15px 30px 75px 30px", text: "An example of how this data is used is for the lights system. Previously, Swapfiets had battery-powered lights, and the data showed that this was the cause of many repairs. So Swapfiets redesigned these, and now they have no batteries or need for cable management, leading to a drastic reduction in repairs. This design has already changed the game, but the ride does not stop here. It is in Swapfiets’ nature to continue striving for a fully circular product."},
                    ]
                },
                // SECTION 3
                {
                    model: "image-image",
                    picture1: {name: "Project2-2.jpg", height: "650px", objectFit: "cover"},
                    picture2: {name: "Project2-3.jpg", height: "650px", objectFit: "cover"},
                },
                // SECTION 4
                {
                    model: "text-text",
                    text1: [
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 2.5% 150px 0", marginSmall: "75px 30px 0 30px", text: "Swapfiets challenged 20 Product Design students from the Amsterdam University of Applied Sciences to redesign their bike lights, and asked them to consider these 4 circular principles throughout their process; design to last, easy to repair, made from renewable content and zero waste.\n\nLots of innovative ideas came out of this challenge. We have interviewed the winning team – Studio Naadloos – to share insights into their approach and perspectives with you.\n\nStudio Naadloos is made up of Wikki van Leijden, Thom Schrama and Julius Kroot, their winning design – Rebate – can be seen on the images on this page."},
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 0 auto 2.5%", marginSmall: "15px 30px 0 30px", text: "Can you tell us about the project, what were you trying to achieve?\n\nIn the beginning we were all a bit stuck with the idea of creating a bicycle light because actually there are already a couple of pretty good options out there, and also the lighting system that Swapfiets is using right now is a pretty good one. The only downside of it is that you can’t take it apart, so at the end of it's life, it’s just a piece of electronic waste. At some point we realised,  we shouldn’t try to reinvent the wheel, but just focus on the issue that we’re facing here and try to see if we can come up with the same sort of system but make it just really easy to repair."},
                    ],
                    text2: [
                        {title: true, widthSmall: "100%", margin: "150px 0 150px 0", marginSmall: "50px 30px 15px 30px", text: "''We shouldn’t try to reinvent the wheel, but just focus on the issue that we’re facing here.''"},
                    ]
                },
                // SECTION 5
                {
                    model: "image",
                    picture1: {name: "Project2-4.jpg", height: "adjust 100", objectFit: "cover"},
                },
                // SECTION 6
                {
                    model: "image-text",
                    picture1: {name: "Project2-5.jpg", margin: "5% 0 5% 0", width: "40%", widthSmall: "100%", marginSmall: "0 0 0 0", objectFit: "cover"},
                    text1: [
                        {title: false, widthSmall: "100%", margin: "10% 30px 30px 30px", marginSmall: "30px 30px 0 30px", text: "How easy is it to do these repairs, is a special skill set required?"},
                        {title: false, widthSmall: "100%", marginSmall: "30px 30px 0 30px", text: "We tried to make it as easy as possible, a big part of this project was to bring down the total cost of ownership, and one of these things is to be very quick in the repairs. So if you want to use a principle which is relying on replacement, everybody is supposed to be able to do it. Especially if there’s a new guy on the job, who needs to install a new light on the breadboard, he needs to be able to do it on his first intuition guess. Pop it open, plug the replacement in and move around again."},
                        {title: false, widthSmall: "100%", marginSmall: "30px 30px 0 30px", text: "The current light is ultrasonically sealed which means that you can’t open it without breaking it. We tried to come up with a way where we could close off the components in the housing so that if water would enter, there would not be a problem. Instead of trying to keep the problem out, we said ''okay let’s just accept that it might happen and then see how we can work with it''. We gave the creeping water its own way to navigate within the housing which will lead it to disappear through the bottom. Since water always takes the easiest route, we just gave it that route."},
                    ]
                },
                // SECTION 7
                {
                    model: "image",
                    picture1: {name: "Project2-6.jpg", height: "adjust 100", objectFit: "cover"},
                },
                // SECTION 8
                {
                    model: "text-text",
                    text1: [
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 2.5% 150px 0", marginSmall: "75px 30px 0 30px", text: "How did you go about choosing the specific materials of the components?\n\nIn the beginning we looked at the very broad scope of what materials were available. In the end a lot of these options came down to the question – is it recyclable? We can work with a very nice bioplastic but then you won’t be able to recycle it, because there’s no system in place. So in the end we came up with trying to use a plastic that is already used by Swapfiets already. Making the amount of materials on a Swapfiets smaller, and by doing so we make the waste flow more single material focussed. That’s the beauty of working for a client like this, as all the materials go back to Swapfiets. We could make the light out of gold and still we would get it back, so we could be a bit more free with the material choices."},
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 0 auto 2.5%", marginSmall: "15px 30px 0 30px", text: "Another thing that we thought was really cool, is the cone that reflects the light, and makes it stronger. The problem with that, is that you need something that is very reflective. You need to chrome a certain material. And chroming is a horrible thing anyway, and you are also blending different materials together – which is never a good idea. I called with a couple people who are doing a lot of sustainable design, and asked them “what is a good alternative for this part?”. And they came up with the idea of using old soda cans for the reflector cone. Which is a waste product already, and then we found a way to reshape them into the product that we needed."},
                    ],
                    text2: [
                        {title: true, widthSmall: "100%", margin: "150px 0 150px 0", marginSmall: "50px 30px 15px 30px", text: "We think it’s a very noble goal to be 100% circular by 2025. After doing this project we are seeing how hard it is to accomplish this goal. We wish Swapfiets goodluck on this mission."},
                    ]
                },
                // SECTION 9
                {
                    model: "image",
                    picture1: {name: "Project2-8.jpg", height: "adjust 100", objectFit: "cover"},
                },
                
            ]
        },
        // PROJECT 3
        {id: 3,
            sections: [
                // SECTION 1
                {
                    model: "image",
                    picture1: {name: "Project3-1.jpg", height: "adjust 100", objectFit: "cover", onMobile: true}
                },
                // SECTION 2
                {
                    model: "text-text",
                    backgroundColor: "white",
                    text1: [
                        {title: false, color: "var(--Black)", marginSmall: "75px auto 0 30px", text: "#projectTitle Project REVERSE\nENGINEERING Year 2020 Client AUAS"},
                    ],
                    text2: [
                        {title: false, color: "var(--Black)", width: "30%", widthSmall: "100%", margin: "100px auto 0 7%", marginSmall: "30px 30px 0 30px", text: "Through reverse engineering, we conduct an in-depth analysis of the original product briefing that preceded the development phase. This approach offers a unique perspective on the design process, presenting valuable insights from an alternative standpoint."},
                        {title: true, color: "var(--Black)", width: "40%", widthSmall: "100%", margin: "150px 45% 50px 0", marginSmall: "30px 30px 30px 30px", text: "Reversed Engineering"},
                        {title: false, color: "var(--Black)", width: "40%", widthSmall: "100%", margin: "0 2.5% 100px 0", marginSmall: "0 30px 0 30px", text: "The product under examination is an electric tacker. Prior to disassembling it, a comprehensive assessment was performed while the product was in operational mode, documenting its capabilities and limitations. This investigation revealed that the internal components possessed sufficient power to drive a nail into solid oak wood but lacked the strength to do so in solid aluminum."},
                        {title: false, color: "var(--Black)", width: "40%",widthSmall: "100%", margin: "0 0 auto 2.5%", marginSmall: "15px 30px 75px 30px", text: "To achieve a comprehensive understanding of the forces involved in the product's operation, the black box concept was employed. By examining the energy entering and leaving the system, we gained a clear understanding of the energy transmission within the product."},
                    ]
                },
                // SECTION 3
                {
                    model: "image-image",
                    picture1: {name: "Project3-2.jpg", height: "650px", objectFit: "cover"},
                    picture2: {name: "Project3-3.jpg", height: "650px", objectFit: "cover"},
                },
                // SECTION 4
                {
                    model: "text-text",
                    text1: [
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 2.5% auto 0", marginSmall: "75px 30px 0 30px", text: "An intriguing aspect of reverse engineering is the identification of weak links in the product design. As often is the case, moving parts are susceptible to early failure. In this instance, the electronic tacker's mechanics generated a magnetic field that propelled the 'hammer' onto a nail or tack."},
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 0 auto 2.5%", marginSmall: "15px 30px 0 30px", text: "While the hammer part was well-engineered, the connecting piece responsible for transferring power to the nail was a fragile metal element. Consequently, it had the potential to lose contact with the outer product shell, rendering the entire product non-functional."},
                    ],
                    text2: [
                        {title: true, widthSmall: "100%", margin: "150px 0 150px 0", marginSmall: "50px 30px 15px 30px", text: "Reverse engineering is an indispensable process for comprehending the intricacies of product design."},
                    ]
                },
                // SECTION 5
                {
                    model: "image",
                    picture1: {name: "Project3-4.jpg", height: "adjust 100", objectFit: "cover"},
                },
                // SECTION 6
                {
                    model: "text-text",
                    text1: [
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 2.5% auto 0", marginSmall: "75px 30px 0 30px", text: "The challenges of reverse engineering are to truly figure out the list of demands prior to the design phase of the product. We can only disassemble the existing product, but it is not always enough to understand why parts have been designed the way they are. "},
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 0 auto 2.5%", marginSmall: "15px 30px 0 30px", text: "Through reverse engineering I have gained a great insight into understanding what goes on behind the shell of the product. Every item, every element is partly responsible for creating the user's experience. To pick a product apart is a delicate action that needs a great amount of attention and respect for the design. The action in its own way creates respect for the design as well."},
                    ],
                    text2: [
                        {title: true, widthSmall: "100%", margin: "150px 0 150px 0", marginSmall: "50px 30px 15px 30px", text: "To pick a product apart is a delicate action that needs a great amount of attention and respect for the design."},
                    ]
                },
                
                
            ]
        },
        // PROJECT 4
        {id: 4,
            sections: [
                // SECTION 1
                {
                    model: "image",
                    picture1: {name: "Project4-1.jpg", height: "adjust 100", objectFit: "cover", onMobile: true}
                },
                // SECTION 2
                {
                    model: "text-text",
                    backgroundColor: "white",
                    text1: [
                        {title: false, color: "var(--Black)", marginSmall: "75px auto 0 30px", text: "#projectTitle Project CONELAMP Year 2022 Client AUAS"},
                    ],
                    text2: [
                        {title: false, color: "var(--Black)", width: "30%", widthSmall: "100%", margin: "100px auto 0 7%", marginSmall: "30px 30px 0 30px", text: "Designed by, and for the modern day worker. By people who know the struggles that come with working from your home."},
                        {title: true, color: "var(--Black)", width: "40%", widthSmall: "100%", margin: "150px 45% 50px 0", marginSmall: "30px 30px 30px 30px", text: "Multifunctional light for the modern worker."},
                        {title: false, color: "var(--Black)", width: "40%", widthSmall: "100%", margin: "0 2.5% 100px 0", marginSmall: "0 30px 0 30px", text: "With housing getting more and more expensive, people start to live smaller. On top of that we have embraced the flexible way of working, where people work 50% of their time or more from home. This asks for new designs, products need to be modular or multi purpose to fit the needs in a smaller and more modular house."},
                        {title: false, color: "var(--Black)", width: "40%",widthSmall: "100%", margin: "0 0 auto 2.5%", marginSmall: "15px 30px 75px 30px", text: "The modern day worker needs light to work with, efficient, bright and focussed light to work with. But since the office is also your living room, you would like to use that same lamp, as a table lamp during dinner. Which asks for a completely different atmosphere. The Conelamp offers you that flexibility. focussed light for work, and atmospheric light for leisure."},
                    ]
                },
                // SECTION 3
                {
                    model: "image",
                    picture1: {name: "Project4-2.jpg", height: "adjust 100", objectFit: "cover", onMobile: true}
                },
                 // SECTION 4
                {
                    model: "image-text",
                    picture1: {name: "Project4-3.jpg", margin: "5% 0 5% 0", width: "40%", widthSmall: "100%", marginSmall: "0 0 0 0", objectFit: "cover"},
                    text1: [
                        {title: true, widthSmall: "100%", margin: "10% 30px 30px 30px", marginSmall: "20px 30px 0 30px", text: "Switch between direct and focussed light for working situations and an indirect glow for moments of relaxation."},
                        {title: false, widthSmall: "100%", marginSmall: "30px 30px 0 30px", text: "To reach the final design, many iterations have been sketched as can be seen above. But the winning design is the one on the right."},
                        {title: false, widthSmall: "100%", marginSmall: "30px 30px 0 30px", text: "Offering the opportunity to switch between direct focussed light for working situations and an indirect light glow for moments of relaxation. That is what makes the Conelamp a must have for the home office of today."},
                    ]
                },
                // SECTION 5
                {
                    model: "text-text",
                    text1: [
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 2.5% auto 0", marginSmall: "75px 30px 0 30px", text: "The challenge was to design a lamp that the designers would want and need themselves. To understand the needs, I researched the designers in my university. I talked with them, observed them to see what it is that they do and what they need. Especially when looking at lighting."},
                        {title: false, width: "40%", widthSmall: "100%", margin: "150px 0 auto 2.5%", marginSmall: "15px 30px 0 30px", text: "What was striking in this research is that most of the target audience was working a lot from their homes, from their dining room tables. Housing in the Netherlands is expensive and many creatives do not have the space for a home office. So they need multifunctional spaces. A dining room that can function as your office."},
                    ],
                    text2: [
                        {title: true, widthSmall: "100%", margin: "150px 0 150px 0", marginSmall: "50px 30px 15px 30px", text: "The Conelamp is there to give designers a multifunctional light that can be used for work and relaxing, while being aesthetically pleasing."},
                    ]
                },
                
                
            ]
        },
    ]




// DON'T TOUCH THIS BELOW
export interface ISections {
    model: string;
    backgroundColor?: string;
    height?: string;
    width?: string;
    margin?: string;
    backgroundColorSmall?: string;
    heightSmall?: string;
    widthSmall?: string;
    marginSmall?: string;
    picture1?: {name: string, width?: string, height?: string, radius?: string, margin?: string, objectFit?: string, widthSmall?: string, heightSmall?: string, radiusSmall?: string, marginSmall?: string, objectFitSmall?: string, onMobile?: boolean};
    picture2?: {name: string, width?: string, height?: string, radius?: string, margin?: string, objectFit?: string, widthSmall?: string, heightSmall?: string, radiusSmall?: string, marginSmall?: string, objectFitSmall?: string};
    text1?: {title: boolean, text: string, color?: string, width?: string, height?: string, margin?: string, colorSmall?: string, widthSmall?: string, heightSmall?: string, marginSmall?: string}[];
    text2?: {title: boolean, text: string, color?: string, width?: string, height?: string, margin?: string, colorSmall?: string, widthSmall?: string, heightSmall?: string, marginSmall?: string}[];
}

export interface ITextProjects {
    projects: {id:number, color: string, background: string, title: string, description: string}[];
    description: {id: number, sections: ISections[]}[];
    noProjectDescription: string;
}

const texts: Map<string, ITextProjects> = new Map<string, ITextProjects>();

const pReturn = (array: {id: number, color: string, background: string, title: string, description: string}[]) => {
    return array
}

const dReturn = (array: {id: number, sections: ISections[]}[]) => {
    return array
}

texts.set('en', {
    projects: pReturn(projects),
    description: dReturn(projectDescription),
    noProjectDescription: "No description available for this project.",
})

export default texts;
