import * as fs from "fs";

export interface ITextHome {
    mainTitle: string[];
    dropdown: string[][];
    about: string
    presentationTitle: string;
    presentationParagraph1: string;
    presentationParagraph2: string;
    presentationParagraph3: string;
    presentationParagraph4: string;
    contact: string;
}

const texts: Map<string, ITextHome> = new Map<string, ITextHome>();

const mainTitle = (start: string, symbol: string, link: string, fst: string, snd: string, trd: string) => {
  return [
      start,
      symbol,
      link,
      fst,
      snd,
      trd
  ]
}

const dropdown = (fst: string[], snd: string[], trd: string[], frh: string[]) => {
    return [
        fst,
        snd,
        trd,
        frh
    ]
}

texts.set('en', {
    mainTitle: mainTitle("creating", "*", "friction", "to make people curious", "and instigate their journey", "of sustainable awareness."),
    dropdown: dropdown(["Questioning and dialogue.", "I want to know where your organization is heading, what’s the plan for the next 5 to 10 years? Then we get to the part where I am probably gonna tell you how many misconceptions there are about living a ‘green’ life.", "How greenwashing has invaded our society and how it’s almost impossible to know what the ‘right’ choice is. But don’t worry, not all hope is gone. We will find a way for you to do your part in creating a world where sustainable choices are available for everyone."],
        ["Environmental assessment.", "There is no way to build a sustainable organization if we are not fully aware of the current situation. Therefore I will approach your organization as a system and expose where your energy, your resources and your emission goes.", "When we have a clear visualization of your environmental impact, we can start to define the actual problem."],
        ["Sustainable strategy.", "After assessing the environment and the impact of your organization we can create a strategy towards a sustainable future. This is the part where it's gonna get tricky. I am going to suggest changes within your organization, and we all know that changing can be hard.", "But that’s what I’m here for. I will look for opportunities to improve the efficiency within the system and mold it into a durable and sustainable alternative of its origin. All to synthesize into a future ready and sustainable organization."],
        ["Process guidance.", "I design the change, I guide the change. By finding the right suppliers and resources, I will be there to test the subsystems and implement them into the bigger system of your organization.", "Whether the changes are small and easily monitored or big and might only show their impact on the long run, I will monitor and evaluate the processes."]),
    about: "about",
    presentationTitle: "How to say something about myself to impress you without being cocky I don't know. But I can tell you why I do what I do.",
    presentationParagraph1: "I worked as an advertising photographer for years, working on amazing brands and projects, traveling around the world to collaborate with other amazing people and exciting agencies. I was doing what I dreamed of. But it wasn’t fulfilling me. (It started to impact my sleep, I started to worry about the products and brand I was working for.) I realized I was part of the consumerism and capitalism that is playing such a big part in the destruction of our beautiful planet. All these products that I was helping to sell, do the people who buy them really need those? How will the people use these products, do they care about it, or will it end up as trash after a week?",
    presentationParagraph2: "It made me realize I didn’t want to be a part of this industry anymore. I know I have to use my energy to improve life instead of helping to destroy it. I figured I either have to be a politician or I have to be at home with people.",
    presentationParagraph3: "So I decided to go back to school and study to become an engineer in the field of Product Design. This way I could design products that would last, that everybody could use and enjoy for a long period of time. But during my studies I realized that we already have so many products. Why do we need a new chair when there are already more than enough chairs in the world to let everybody sit down.",
    presentationParagraph4: "That is when I switched my focus from designing everyday products to designing durable and sustainable systems. Since making the ‘right’, the ‘green’ or the sustainable choice is incredibly difficult and it is always depending on its context.",
    contact: "contact"
})

export default texts;