export interface ITextCounter {
    total: string;
    sinceReset: string;
    reset: string;
    beenReset: string;
    countProjectsKeys: string[];
}

const texts: Map<string, ITextCounter> = new Map<string, ITextCounter>();

const countProjectsKeys =
    [
        "c02e32b1-ff23-4de5-ab9e-eabeb5433497",
        "42e9fc4e-3a52-4d53-b703-7cf42f7f7ac3",
        "9ec02bd0-b8e6-4f59-b43a-e417d31c65f6",
        "067b0e1d-3459-4434-b2ed-aa60b4a1e3b2",
        "5d5d2e74-d2f0-46dc-b15c-7708f0300a89",
        "875cfb62-326f-4966-9547-74f3b79961ed",
        "10a6a3e2-54b9-4e3d-91de-8e74542c554d",
        "67ac289e-b39e-4a63-b15b-aa8ca745fb84",
        "752c9f9a-3b5f-496c-9d95-64ade8c7af19",
        "42def0b7-31c5-44ad-aa54-8669c53594d8",
        "b53ab835-b53a-4b89-957f-0525da8db5dd",
        "ad000e3b-4078-42ec-94e7-dab346051aa0",
        "dfa3e86e-316f-4782-9464-d8951edb3727",
        "283fcf5d-0f94-4f14-af58-89a0691b72df",
        "fbb25782-c6de-4c0d-9b1f-1152d18b889d",
    ]

texts.set('en', {
    total: "Total",
    sinceReset: "Since last reset",
    reset: "Reset",
    beenReset: "This counter has been reset",
    countProjectsKeys: countProjectsKeys
})

export default texts;