import React from "react";
import { Button } from 'theme-ui'
import "../../assets/css/myButton.css"

interface Props {
    value: string
    onClick?: () => void;
    onDoubleClick?: () => void;
    styles?: object;
    stylesHovered?: object;
    className?: string;
    ref?: (node?: (Element | null | undefined)) => void;
    disabled?: boolean
    buttonColor?: string
    id?: string;
}

export const MyButton = (props: Props) => {
    const [hovered, setHovered] = React.useState(false);

    return <Button
        id={props.id}
        ref={props.ref}
        sx={{
            color: "myWhite",
            background: props.buttonColor ? props.buttonColor : "var(--Black)",
            padding: "6px 25px 6px 25px",
            transition: "all 0.2s ease",
            fontFamily: "myRegular",
            fontSize: "var(--MidFontSize)",
            border: props.buttonColor && props.className !== "MyAnimatedButton" ? "2px solid "+props.buttonColor : props.className !== "MyAnimatedButton" ? "2px solid var(--Black)" : "",
            borderRadius: "15px",
            boxShadow: "0px 0px 6px 1px rgba(0, 0, 0, 0.08)",
            "&:hover": {
                border: props.buttonColor && props.className !== "MyAnimatedButton" ? "2px solid "+props.buttonColor : props.className !== "MyAnimatedButton" ? "2px solid var(--White)" : "",
                cursor: "pointer"
            },
            "&:disabled": {
              border: "2px solid var(--Grey)",
              cursor: "not-allowed",
              background: "var(--Grey)"
            }
        }}
        className={props.className}
        disabled={props.disabled}
        style={hovered ? props.stylesHovered ? props.stylesHovered : props.styles : props.styles}
        onDoubleClick={props.onDoubleClick}
        onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}} onClick={props.onClick}>
        {props.value}
    </Button>
};

export default MyButton;
