export interface IText {
    unfound: string
    home: string
}

const texts: Map<string, IText> = new Map<string, IText>();

texts.set('en', {
    unfound: "The page you’re looking for can’t be found.",
    home: "Back to the home page"
})

export default texts;