import {createContext, useContext, useState} from "react";

const AuthContext = createContext(undefined as any);

type ProviderAuthT = {
    auth: boolean,
    setAuth: (value: boolean) => void,
}

export const useAuth = () : ProviderAuthT => {
    return useContext(AuthContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const AuthProvider = ({children}: IProps) => {
    const [auth, setAuth] = useState(false);

    const value = {
        auth,
        setAuth
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
