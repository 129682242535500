import React, {useEffect, useState} from "react";
import "./../assets/css/constants.css";
import {useText} from "../assets/contexts/textContext";
import texts, {ITextCounter} from '../assets/texts/counter';
import textsProjects, {ITextProjects} from '../assets/texts/projects';
import {Flex} from 'rebass';
import MyText from "../components/basics/MyText";
import ArrowNavigation from "../components/navigation/arrowNavigation";
import CountUp from "react-countup";
import countapi from "countapi-js";
import MyConfirmationDialog from "../components/others/MyConfirmationDialog";
import MyButton from "../components/basics/MyButton";

const Counter = () => {
    const language = useText().currentLanguage;
    const [count, setCount] = useState(0);
    const [countTotal, setCountTotal] = useState(0);
    const [countProjects, setCountProjects] = useState(Array((texts.get(language) as ITextCounter).countProjectsKeys.length).fill(-1));
    const [open, setOpen] = useState(["", ""] as [string, string]);
    const countProjectsTmp = Array((texts.get(language) as ITextCounter).countProjectsKeys.length).fill(0 as number);

    const updateCountProjects = (index: number, value: number) => {
        countProjectsTmp[index] = value;
    }

    const updateCountProjectsDirect = (index: number, value: number) => {
        countProjectsTmp[index] = value;
        setTimeout(() => {
            setCountProjects(countProjectsTmp);
        }, 500)
    }

    const getValues = () => {
        countapi.get('counterthomrobinschrama.com', '71e4a9ca-e0a1-4446-add6-1fb66d7de8c5').then((result) => { setCount(result.value) });
        countapi.get('counterthomschramatotal.com', '7ec087a4-1ac0-4ccd-bf76-9d1824cbcf19').then((result) => { setCountTotal(result.value) });
        countProjects.map((value, index) => {
            countapi.get('counterthomschramap'+(index+1)+'.com', (texts.get(language) as ITextCounter).countProjectsKeys.at(index) as string).then((result) => {updateCountProjects(index, result.value)});
        })
        setTimeout(() => {
            setCountProjects(countProjectsTmp);
        }, 500)
    }

    setInterval(function(){
        getValues()
    }, 30000)

    useEffect(()=> {
        getValues();
    },[])

    interface IProjectCounter {
        index: number,
        value: number
    }

    const ProjectCounter = (props: IProjectCounter) => {
        return (
            <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} margin={"50px"} backgroundColor={"var(--Grey)"} padding={"30px"} style={{borderRadius: "15px"}}>
                <MyText value={"Project "+(props.index+1)} style={{fontSize: "var(--TitleFontSize)", color: "var(--White)", marginTop: "20px", marginBottom: "20px"}}/>
                <CountUp
                    start={0}
                    end={props.value}
                    duration={3.2}
                    useEasing={true}
                    delay={0.5}
                    style={{color: "var(--White)", fontSize: "var(--GiantFontSize)"}}
                />
                <MyButton value={(texts.get(language) as ITextCounter).reset} styles={{width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "30px"}} stylesHovered={{width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "30px"}} onClick={() => {setOpen(["counterthomschramap"+(props.index+1)+".com", (texts.get(language) as ITextCounter).countProjectsKeys.at(props.index) as string])}}/>
            </Flex>
        )
    }

    return (
        <Flex flexDirection={"column"}>
            {(open.at(0) !== "" && open.at(1) !== "") && <MyConfirmationDialog onClose={() => setOpen(["", ""])} onConfirm={() => {countapi.set(open.at(0) as string, open.at(1) as string, 0).then((result) => { alert((texts.get(language) as ITextCounter).beenReset)}); open.at(0) === "counterthomrobinschrama.com" ? setCount(0) : setTimeout(() => getValues(), 500)}}/>}
            <Flex flexDirection={"column"} backgroundColor={"var(--DarkGrey)"} minHeight={"100vh"} marginTop={"var(--HeaderHeight)"}>
                <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} marginTop={"100px"} marginBottom={"100px"}>
                    <CountUp
                        start={0}
                        end={countTotal}
                        duration={3.2}
                        useEasing={true}
                        delay={0.5}
                        style={{color: "var(--White)", fontSize: "var(--GiantFontSize)"}}
                    />
                    <MyText value={(texts.get(language) as ITextCounter).total} style={{fontSize: "var(--TitleFontSize)", color: "var(--White)", marginTop: "20px"}}/>
                </Flex>
                <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} marginBottom={"100px"}>
                    <CountUp
                        start={0}
                        end={count}
                        duration={3.2}
                        useEasing={true}
                        delay={0.5}
                        style={{color: "var(--White)", fontSize: "var(--GiantFontSize)"}}
                    />
                    <MyText value={(texts.get(language) as ITextCounter).sinceReset} style={{fontSize: "var(--TitleFontSize)", color: "var(--White)", marginTop: "20px", marginBottom: "10px"}}/>
                    <MyButton value={(texts.get(language) as ITextCounter).reset} styles={{width: "250px", marginLeft: "auto", marginRight: "auto"}} stylesHovered={{width: "250px", marginLeft: "auto", marginRight: "auto"}} onClick={() => {setOpen(["counterthomrobinschrama.com", "71e4a9ca-e0a1-4446-add6-1fb66d7de8c5"])}}/>
                </Flex>
                <Flex flexWrap={"wrap"} alignItems={"center"} justifyContent={"center"} marginLeft={"50px"} marginRight={"50px"}>
                    {countProjects.at(0) !== -1 && countProjects.map((value, index) => {
                        return (
                            index < (textsProjects.get(language) as ITextProjects).projects.length ?
                                <ProjectCounter key={index} index={index} value={value}/>
                                :
                                null
                        )
                    })}
                </Flex>
                <ArrowNavigation page={"friction"}/>
            </Flex>
        </Flex>
    );
}

export default Counter;