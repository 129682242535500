import React from "react";
import "../../assets/css/constants.css";
import {useText} from "../../assets/contexts/textContext";
import texts, {IText} from "../../assets/texts/footer";
import {Flex} from 'rebass';
import MyLink from "../basics/MyLink";
import MyText from "../basics/MyText";
import {useWindowDimensions} from "../../assets/contexts/windowDimensionsContext";

const Footer = () => {
    const language = useText().currentLanguage;
    const windowDimensions = useWindowDimensions();

    return (
        <Flex alignItems={"center"} height={windowDimensions.wWCheck(600) ? "var(--FooterHeight)" : "var(--FooterHeightMobile)"} width={"100%"} backgroundColor={"var(--Black)"}>
            <MyLink value={(texts.get(language) as IText).linkedin} style={{color: "var(--White)", marginLeft: "65px", textUnderlineOffset: "3px", fontFamily: "Inter-Light", fontSize: "16px", lineHeight: "115%", letterSpacing: "-4%"}} styleHovered={{color: "var(--White)", marginLeft: "65px", textDecoration: "underline", textUnderlineOffset: "3px", cursor: "pointer", fontFamily: "Inter-Light", fontSize: "16px", lineHeight: "115%", letterSpacing: "-4%"}} onClick={() => window.open((texts.get(language) as IText).linkedinLink)}/>
            <MyLink value={(texts.get(language) as IText).instagram} style={{color: "var(--White)", marginLeft: "40px", textUnderlineOffset: "3px", fontFamily: "Inter-Light", fontSize: "16px", lineHeight: "115%", letterSpacing: "-4%"}} styleHovered={{color: "var(--White)", marginLeft: "40px", textDecoration: "underline", textUnderlineOffset: "3px", cursor: "pointer", fontFamily: "Inter-Light", fontSize: "16px", lineHeight: "115%", letterSpacing: "-4%"}} onClick={() => window.open((texts.get(language) as IText).instagramLink)}/>
            <MyText value={(texts.get(language) as IText).year} style={{marginLeft: "auto", marginRight: "65px", color: "var(--White)", fontFamily: "Inter-Light", fontSize: "16px", lineHeight: "115%", letterSpacing: "-4%"}}/>
        </Flex>
    );
}

export default Footer;