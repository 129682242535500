import {BrowserRouter, Route, Routes} from "react-router-dom";
import theme from "./theme";
import { ThemeProvider } from 'theme-ui';
import TextProvider from "./assets/contexts/textContext";
import WindowDimensionsProvider from "./assets/contexts/windowDimensionsContext";
import MenuProvider from "./assets/contexts/MenuContext";
import CardProvider from "./assets/contexts/CardContext";
import HomeProvider from "./assets/contexts/HomeContext";
import {useEffect} from "react";
import MyRoutes from "./MyRoutes";
import Header from "./components/navigation/header";
import Footer from "./components/navigation/footer";
import countapi from "countapi-js";
import "./assets/css/constants.css"
import AuthProvider from "./assets/contexts/AuthContext";

export function App() {

    useEffect(() => {
        if (window.location.pathname.toLowerCase() !== "/counter") {
            countapi.get("counterthomrobinschrama.com","71e4a9ca-e0a1-4446-add6-1fb66d7de8c5").then((result) => {countapi.set('counterthomrobinschrama.com', '71e4a9ca-e0a1-4446-add6-1fb66d7de8c5', Number(result.value + 1)).then((result) => {  })});
            countapi.get('counterthomschramatotal.com', '7ec087a4-1ac0-4ccd-bf76-9d1824cbcf19').then((result) => {countapi.set('counterthomschramatotal.com', '7ec087a4-1ac0-4ccd-bf76-9d1824cbcf19', Number(result.value + 1)).then((result) => {  })});
        }
    },[])

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <TextProvider>
                    <MenuProvider>
                        <CardProvider>
                            <HomeProvider>
                                <AuthProvider>
                                    <WindowDimensionsProvider>
                                        <Header/>
                                        <MyRoutes/>
                                        <Footer/>
                                    </WindowDimensionsProvider>
                                </AuthProvider>
                            </HomeProvider>
                        </CardProvider>
                    </MenuProvider>
                </TextProvider>
            </BrowserRouter>
        </ThemeProvider>
  );
}

export default App;