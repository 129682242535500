import {createContext, useContext, useEffect, useState} from "react";

const CardContext = createContext(undefined as any);

type ProviderCardT = {
    selected: string,
    setSelected: (value: string) => void,
    opened: string[],
    setOpened: (value: string[]) => void,
    first: boolean,
    setFirst: (value: boolean) => void,
    second: boolean,
    setSecond: (value: boolean) => void,
    third: boolean,
    setThird: (value: boolean) => void,
    fourth: boolean,
    setFourth: (value: boolean) => void
}

export const useCard = () : ProviderCardT => {
    return useContext(CardContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const CardProvider = ({children}: IProps) => {
    const [selected, setSelected] = useState("");
    const [opened, setOpened] = useState([] as string[]);
    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [fourth, setFourth] = useState(false);

    useEffect(() => {
        if (first) {
            setSecond(false);
            setThird(false);
            setFourth(false);
        }
    }, [first])

    useEffect(() => {
        if (second) {
            setFirst(false);
            setThird(false);
            setFourth(false);
        }
    }, [second])

    useEffect(() => {
        if (third) {
            setFirst(false);
            setSecond(false);
            setFourth(false);
        }
    }, [third])

    useEffect(() => {
        if (fourth) {
            setFirst(false);
            setSecond(false);
            setThird(false);
        }
    }, [fourth])

    const value = {
        selected,
        setSelected,
        opened,
        setOpened,
        first,
        setFirst,
        second,
        setSecond,
        third,
        setThird,
        fourth,
        setFourth
    }

    return (
        <CardContext.Provider value={value}>
            {children}
        </CardContext.Provider>
    );
}

export default CardProvider;
