import React, {useEffect, useRef} from "react";
import "./../assets/css/constants.css";
import {useText} from "../assets/contexts/textContext";
import texts, {IText} from '../assets/texts/friction';
import textsHome, {ITextHome} from '../assets/texts/home';
import {Box, Flex} from 'rebass';
import MyText from "../components/basics/MyText";
import MyLink from "../components/basics/MyLink";
import ArrowNavigation from "../components/navigation/arrowNavigation";
import Scroll from "react-scroll";
import {useNavigate} from "react-router-dom";
import MyImage from "../components/basics/MyImage";
import Picture from "../assets/pictures/friction.png";
import textsHeader, {ITextHeader} from "../assets/texts/header";
import ArrowRight from "../assets/icons/arrowRight.svg";
import {useWindowDimensions} from "../assets/contexts/windowDimensionsContext";
import {useInView} from "react-intersection-observer";
import {useHome} from "../assets/contexts/HomeContext";

interface PropsSection1 {
    ref1: any;
    inViewRef1: any;
}

interface PropsSection2 {
    ref2: any;
    inViewRef2: any;
}

const Section1 = (props: PropsSection1) => {
    const windowDimensions = useWindowDimensions();
    const language = useText().currentLanguage;
    const scroll = Scroll.animateScroll;
    const navigate = useNavigate();
    const homeContext = useHome();

    return (
            <Flex marginLeft={"var(--PageMargin)"} marginRight={"var(--PageMargin)"} marginTop={"240px"} marginBottom={"150px"} style={{position: "relative"}}>
                <Flex flexWrap={"wrap"} alignItems={"center"}>
                    <MyText value={(textsHome.get(language) as ITextHome).mainTitle[0]} style={{color: "var(--White)", fontSize: "var(--GiantFontSize)", lineHeight: "93.5%", letterSpacing: "-4%", visibility: "hidden"}}/>
                    <Flex alignItems={"center"} onClick={() => navigate("/")}>
                        <MyText value={(textsHome.get(language) as ITextHome).mainTitle[1]} style={{color: "var(--White)", fontSize: "var(--FrictionAsteriscFontSize)", fontFamily: "Libre-Baskerville-Italic"}}/>
                        <MyLink value={(textsHome.get(language) as ITextHome).mainTitle[2]} style={{color: "var(--White)", fontSize: "var(--FrictionFontSize)", textDecoration: "underline", textUnderlineOffset: "8px", textDecorationThickness: "2px", fontFamily: "Libre-Baskerville-Italic"}} onClick={() => {scroll.scrollToTop({duration: 0}); navigate("/friction")}}/>
                    </Flex>
                </Flex>
                <Flex ref={props.ref1} className={props.inViewRef1 ? "fadeDisplayAnimRevert" : "noOpacity"} flexDirection={"column"} width={"230px"} paddingTop={"var(--PageTitleFontSize)"} style={{position: "absolute", top: windowDimensions.wWCheck(680) ? "100px" : "150px", left: "0", right: "0", margin: "auto"}}>
                    <MyText value={(texts.get(language) as IText).first} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light"}}/>
                    <MyText value={(texts.get(language) as IText).second} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light", marginTop: "15px"}}/>
                    <MyText value={(texts.get(language) as IText).third} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light", marginTop: "15px"}}/>
                </Flex>
            </Flex>
    )
}

const Section2 = (props: PropsSection2) => {
    const windowDimensions = useWindowDimensions();
    const language = useText().currentLanguage;

    return (
        windowDimensions.wWCheck(1000) ?
            <Flex ref={props.ref2} className={props.inViewRef2 ? "fadeDisplayAnimRevert2" : "noOpacity"} width={"100%"} alignItems={"center"} marginRight={"55px"} marginTop={"150px"}>
                <MyImage src={Picture} style={{width: "27%", minWidth: "350px", objectFit: "contain", marginLeft: "55px"}}/>
                <Flex flexDirection={"column"} width={"50%"} marginLeft={"75px"} marginRight={"55px"}>
                    <MyText value={(texts.get(language) as IText).title} style={{color: "var(--White)", fontSize: "var(--PageTitleFontSize)", maxWidth: "470px"}}/>
                    <MyText value={(texts.get(language) as IText).subtitle} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light", marginTop: "10px"}}/>
                    <Flex justifyContent={"space-between"} width={"100%"} marginTop={"35px"}>
                        <MyText value={(texts.get(language) as IText).paragraph1} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light", width: "47%"}}/>
                        <MyText value={(texts.get(language) as IText).paragraph2} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light", width: "47%"}}/>
                    </Flex>
                </Flex>
            </Flex>
            :
            <Flex ref={props.ref2} className={props.inViewRef2 ? "fadeDisplayAnimRevert2" : "noOpacity"} flexDirection={"column"} justifyContent={"center"} width={"100%"} alignItems={"center"} marginTop={"100px"}>
                <MyImage src={Picture} style={{width: "100%", minWidth: "350px", objectFit: "contain"}}/>
                <Flex flexDirection={"column"} marginLeft={"30px"} marginRight={"3Opx"} marginTop={"50px"}>
                    <MyText value={(texts.get(language) as IText).title} style={{color: "var(--White)", fontSize: "var(--PageTitleFontSize)", maxWidth: "470px", marginRight: "30px"}}/>
                    <MyText value={(texts.get(language) as IText).subtitle} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light", marginTop: "10px"}}/>
                    <Flex flexDirection={"column"} justifyContent={"space-between"} marginTop={"35px"} marginRight={"30px"}>
                        <MyText value={(texts.get(language) as IText).paragraph1} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light", marginBottom: "15px"}}/>
                        <MyText value={(texts.get(language) as IText).paragraph2} style={{color: "var(--White)", fontSize: "var(--Small)", fontFamily: "Inter-Light"}}/>
                    </Flex>
                </Flex>
            </Flex>
    )
}

const Friction = () => {
    const language = useText().currentLanguage;
    const windowDimensions = useWindowDimensions();
    const [ref1, inViewRef1] = useInView({triggerOnce: true})
    const [ref2, inViewRef2] = useInView({triggerOnce: true})
    const homeContext = useHome();

    useEffect(() => {
        homeContext.setFrictionClicked(false);
    }, [])

    const Section3 = () => {
        return (
            windowDimensions.wWCheck(1000) ?
                <Box width={"60%"} marginTop={"150px"} marginBottom={"100px"} marginLeft={"auto"} marginRight={"auto"} style={{position: "relative"}}>
                    <Flex flexDirection={"column"} width={"50%"}>
                        <MyText value={(texts.get(language) as IText).sentence} style={{color: "var(--White)", fontSize: "var(--TitleFontSize)"}}/>
                        <MyText value={(texts.get(language) as IText).sentenceDescription} style={{color: "var(--White)", fontSize: "var(--Small)", marginTop: "10px"}}/>
                    </Flex>
                    <Flex width={"150px"} marginLeft={"auto"} marginTop={"5px"} style={{position: "relative"}} onClick={() => window.location.href = ("mailto:"+(textsHeader.get(language) as ITextHeader).mailto)}>
                        <MyLink value={(texts.get(language) as IText).contact} style={{color: "var(--White)", fontSize: "var(--TitleFontSize)", textDecoration: "underline", textUnderlineOffset: "5px"}} styleHovered={{color: "var(--White)", fontSize: "var(--TitleFontSize)", textDecoration: "underline", textUnderlineOffset: "5px"}}/>
                        <MyImage src={ArrowRight} style={{scale: "0.9", marginTop: "2px", marginLeft: "5px"}}/>
                    </Flex>
                </Box>
                :
                <Box width={"100%"} marginTop={"50px"} marginBottom={"50px"} marginLeft={"30px"} marginRight={"3Opx"} style={{position: "relative"}}>
                    <Flex flexDirection={"column"} maxWidth={"310px"}>
                        <MyText value={(texts.get(language) as IText).sentence} style={{color: "var(--White)", fontSize: "var(--TitleFontSize)"}}/>
                        <MyText value={(texts.get(language) as IText).sentenceDescription} style={{color: "var(--White)", fontSize: "var(--Small)", marginTop: "10px"}}/>
                    </Flex>
                    <Flex width={"100px"} marginLeft={"auto"} marginRight={"30px"} marginTop={"5px"} style={{position: "relative"}} onClick={() => window.location.href = ("mailto:"+(textsHeader.get(language) as ITextHeader).mailto)}>
                        <MyLink value={(texts.get(language) as IText).contact} style={{color: "var(--White)", fontSize: "var(--TitleFontSize)", textDecoration: "underline", textUnderlineOffset: "5px"}} styleHovered={{color: "var(--White)", fontSize: "var(--TitleFontSize)", textDecoration: "underline", textUnderlineOffset: "5px"}}/>
                        <MyImage src={ArrowRight} style={{scale: "0.9", marginTop: "2px"}}/>
                    </Flex>
                </Box>
        )
    }

    return (
        <Flex flexDirection={"column"}>
            <Flex flexDirection={"column"} backgroundColor={"var(--Grey)"} minHeight={"100vh"}>
                <Section1 ref1={ref1} inViewRef1={inViewRef1}/>
                <Section2 ref2={ref2} inViewRef2={inViewRef2}/>
                <Section3/>
                <ArrowNavigation page={"friction"}/>
            </Flex>
        </Flex>
    );
}

export default Friction;