export interface IText {
    homeTop: string
    home: string
    next: string
    back: string
}

const texts: Map<string, IText> = new Map<string, IText>();

texts.set('en', {
    homeTop: "back up",
    home: "home",
    next: "next",
    back: "back"
})

export default texts;