import React from "react";
import {Flex} from "rebass";
import MyText from "./../basics/MyText"
import "../../assets/css/constants.css"
import MyButton from "./../basics/MyButton";

interface Props {
    onClose: () => void
    onConfirm: () => void
}

export const MyConfirmationDialog = (props: Props) => {

    const confirm = () => {
        props.onConfirm()
        props.onClose()
    }

    return (
        <Flex style={{position: "fixed", width: "100%", height: "90%", padding: "10px", zIndex: "15"}}>
            <Flex flexDirection={"column"} backgroundColor={"var(--White)"} height={"20%"} minHeight={"150px"} minWidth={"300px"} marginLeft={"auto"} marginRight={"auto"} marginTop={"auto"} marginBottom={"auto"} style={{borderRadius: "15px", border: "4px solid var(--Green)", position: "relative"}} alignItems={"center"}>
                <MyText value={"Remettre le compteur à 0"} style={{fontSize: "var(--TitleFontSize)", marginTop: "30px", marginLeft: "30px", marginRight: "30px"}}/>
                <Flex marginTop={"auto"} marginBottom={"10px"}>
                    <MyButton value={"Annuler"} styles={{width: "130px", marginRight: "5px"}} stylesHovered={{width: "130px", marginRight: "5px"}} onClick={props.onClose}/>
                    <MyButton value={"Confirmer"} styles={{width: "130px", marginLeft: "5px"}} stylesHovered={{width: "130px", marginLeft: "5px"}} onClick={confirm}/>
                </Flex>
            </Flex>
        </Flex>
    )
};

export default MyConfirmationDialog;