import "./assets/css/constants.css";

export const theme = {
    fonts: {
        myBlack: 'Inter-Black',
        myBold: 'Inter-Bold',
        myExtraBold: 'Inter-ExtraBold',
        myExtraLight: 'Inter-ExtraLight',
        myLight: 'Inter-Light',
        myMedium: 'Inter-Medium',
        myRegular: 'Inter-Regular',
        mySemiBold: 'Inter-SemiBold',
        myThin: 'Inter-Thin',
    }
}

export default theme;