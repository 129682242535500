import React, {useEffect, useRef, useState} from "react";
import "../../assets/css/constants.css";
import {useText} from "../../assets/contexts/textContext";
import texts, {ITextHeader} from "../../assets/texts/header";
import {Box, Flex} from 'rebass';
import MyImage from "../basics/MyImage";
import MyLink from "../basics/MyLink";
import Logo from "../../assets/icons/logo.svg";
import {useNavigate} from "react-router-dom";
import Scroll from "react-scroll";
import {useWindowDimensions} from "../../assets/contexts/windowDimensionsContext";
import {WindowHeight, WindowWidth} from "../../assets/utilis/windowDimensions";
import {stack as Menu} from 'react-burger-menu'
import { FiMenu as BurgerIcon } from 'react-icons/fi';
import {useCard} from "../../assets/contexts/CardContext";
import {useHome} from "../../assets/contexts/HomeContext";
import LogoIcon from "../../assets/icons/logo.svg";
import {useMenu} from "../../assets/contexts/MenuContext";

const Header = () => {
    const language = useText().currentLanguage;
    const navigate = useNavigate();
    const scroll = Scroll.animateScroll;
    const windowDimensions = useWindowDimensions();
    const homeContext = useHome();
    const stickyRef = useRef<HTMLDivElement>(null);
    const [isSticky, setIsSticky] = useState(false);
    let ok = false
    const menuContext = useMenu();
    const cardContext = useCard();

    useEffect(() => {
        function handleResize() {
            windowDimensions.setWindowWidth(WindowWidth())
            windowDimensions.setWindowHeight(WindowHeight())
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        menuContext.setDescriptionAvailable(false);
    }, [window.location.href]);

    useEffect(() => {
            const handleScroll = () => {
        if (!ok && !windowDimensions.wWCheck(600)) {
                if (!isSticky && window.scrollY > window.innerHeight + 200) {
                    ok = true
                    setIsSticky(true)
                    // scroll.scrollTo(200, {duration: 0})
                }
        }
            };

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
    }, []);

    const MenuNormal = () => {
        const cardContext = useCard();

        return (
            <Flex alignItems={"center"} width={"100%"} height={"var(--HeaderHeight)"} style={{position: "fixed", top: "0px", zIndex: 1}}>
                <MyImage src={Logo} style={{width: "52px", height: "52px", marginLeft: "65px"}} onclick={() => {cardContext.setSelected(""); navigate("/")}}/>
                <Flex alignItems={"center"} justifyContent={"end"} height={"70px"} width={"100%"}>
                    <Flex justifyContent={"space-evenly"} minWidth={"400px"} marginRight={"65px"}>
                        <MyLink value={(texts.get(language) as ITextHeader).about} style={{color: "var(--White)", textUnderlineOffset: "3px", fontSize: "14px"}} styleHovered={{color: "var(--White)", textDecoration: "underline", textUnderlineOffset: "3px", fontSize: "14px", cursor: "pointer"}} onClick={() => navigate("/?scrollto=about")}/>
                        <MyLink value={(texts.get(language) as ITextHeader).projects} style={{color: "var(--White)", textUnderlineOffset: "3px", fontSize: "14px"}} styleHovered={{color: "var(--White)", textDecoration: "underline", textUnderlineOffset: "3px", fontSize: "14px", cursor: "pointer"}} onClick={() => navigate("/?scrollto=projects")}/>
                        <MyLink value={(texts.get(language) as ITextHeader).friction} style={{color: "var(--White)", textUnderlineOffset: "3px", fontSize: "14px"}} styleHovered={{color: "var(--White)", textDecoration: "underline", textUnderlineOffset: "3px", fontSize: "14px", cursor: "pointer"}} onClick={() => {scroll.scrollToTop(); homeContext.setFrictionClicked(true); setTimeout(() => navigate("/friction"), 700)}}/>
                        <MyLink value={(texts.get(language) as ITextHeader).contact} style={{color: "var(--White)", textUnderlineOffset: "3px", fontSize: "14px"}} styleHovered={{color: "var(--White)", textDecoration: "underline", textUnderlineOffset: "3px", fontSize: "14px", cursor: "pointer"}} onClick={() => window.location.href = ("mailto:"+(texts.get(language) as ITextHeader).mailto)}/>
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    const BurgerMenu = () => {
        const styles = {
            bmBurgerButton: {
                position: "fixed",
                width: "36px",
                height: "36px",
                right: "10px",
                top: "-160px",
            },
            bmCrossButton: {
                marginTop: "15px",
                marginRight: "30px",
            },
            bmCross: {
                marginRight: "30px",
                background: "var(--White)",
            },
            bmMenuWrap: {
                position: "fixed",
                top: "0",
                boxShadow: "0px 0 16px 0px rgba(0, 0, 0, 0.2)",
                background: "var(--Black)",
            },
            bmMenu: {
                position: "fixed",
                top: "0",
                background: "var(--Black)",
            },
            bmMorphShape: {
                fill: "var(--White)",
                outline: "none"
            },
            bmItemList: {
                color: '#b8b7ad',
                padding: '5em 5em',
            },
        }

        const Logo = () => {
            return (
                !windowDimensions.wWCheck(1000) ?
                    <Flex backgroundColor={"var(--Grey)"} alignItems={"center"} justifyContent={"center"} height={"100vh"} width={"100%"}>
                        <MyImage ref={stickyRef} src={LogoIcon} style={{width: "52px", position: "sticky", top: "77px"}} onclick={() => navigate("/")}/>
                    </Flex>
                    :
                    null
            )
        }

        return (
            <Box>
                <Flex className={"burgerMenuIcon"} onClick={() => menuContext.toggleMenu()}>
                    <BurgerIcon color={"var(--White)"} size={"25"}/>
                </Flex>
                {window.location.pathname === "/" && <Logo/>}
                <Flex alignItems={"end"} justifyContent={"center"} width={"100%"} height={"var(--HeaderHeight)"} style={{position: "absolute", top: "0px"}}>
                    {menuContext.isOverlay ? <Box className={"overlay"} sx={{height: windowDimensions.windowHeight, width: windowDimensions.windowWidth , cursor: "pointer", opacity: "60%"}}/> : null}
                    {(window.location.pathname === "/" && isSticky) || window.location.pathname !== "/" && <MyImage src={LogoIcon} style={{width: "52px", height: "52px"}} onclick={() => {cardContext.setSelected(""); navigate("/")}}/>}
                </Flex>
                <Menu isOpen={menuContext.isOpen} styles={styles} onClose={() => menuContext.toggleMenu()}>
                    <MyLink value={(texts.get(language) as ITextHeader).about} style={{color: "var(--White)", textUnderlineOffset: "3px", fontSize: "var(--PageTitleFontSize)", marginBottom: "15px"}} onClick={() => {menuContext.toggleMenu(); navigate("/?scrollto=about")}}/>
                    <MyLink value={(texts.get(language) as ITextHeader).projects} style={{color: "var(--White)", textUnderlineOffset: "3px", fontSize: "var(--PageTitleFontSize)", marginBottom: "15px"}}  onClick={() => {menuContext.toggleMenu(); navigate("/?scrollto=projects")}}/>
                    <MyLink value={(texts.get(language) as ITextHeader).friction} style={{color: "var(--White)", textUnderlineOffset: "3px", fontSize: "var(--PageTitleFontSize)", marginBottom: "15px"}}  onClick={() => {menuContext.toggleMenu(); scroll.scrollToTop(); if (!window.location.href.includes("/friction")) {homeContext.setFrictionClicked(true); setTimeout(() => navigate("/friction"), 700)}}}/>
                    <MyLink value={(texts.get(language) as ITextHeader).contact} style={{color: "var(--White)", textUnderlineOffset: "3px", fontSize: "var(--PageTitleFontSize)", marginBottom: "15px"}} onClick={() => {menuContext.toggleMenu(); window.location.href = ("mailto:"+(texts.get(language) as ITextHeader).mailto)}}/>
                </Menu>
            </Box>
        )
    }

    return (
        windowDimensions.wWCheck(600) ? <MenuNormal/> : <BurgerMenu/>
    );
}

export default Header;