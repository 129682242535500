import React from "react";
import {Image} from 'theme-ui'

interface Props {
    src: string
    style?: object;
    stylesHovered?: object;
    onclick?: () => void;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    id?: string;
    ref?: any;
}

export const MyImage = (props: Props) => {
    const [hovered, setHovered] = React.useState(false);

    return (
        <Image
            ref={props.ref}
            id={props.id}
            src={props.src}
            sx={{
                transition: "all 0.2s ease",
                cursor: props.onclick ? 'pointer' : 'default',
            }}
            onMouseOver={props.onMouseOver}
            onMouseOut={props.onMouseOut}
            style={hovered && props.stylesHovered ? props.stylesHovered : props.style}
            onMouseEnter={() => {
                setHovered(true)
            }} onMouseLeave={() => {
            setHovered(false)
        }} onClick={props.onclick}
        />
    )
};

export default MyImage;